import React, { useContext, useEffect, useState } from "react";
import { Table, Modal, Button, Form } from "react-bootstrap";
import { Description, Edit, Delete } from "@mui/icons-material";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { MyContext } from "../../context/Context";

const HomeProductEnquiry = () => {

  const [open, setOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedCareer, setSelectedCareer] = useState(null);


const {PORT , getHomeProductEnquiry ,homeProductEnquiry} = useContext(MyContext)

useEffect(()=>{
  getHomeProductEnquiry();
},[])


    const handleEditOpen = (careerData) => {
      setSelectedCareer({ ...careerData });
      setEditModal(true);
    };
  
    // Handle Edit Change
    const handleEditChange = (e) => {
      setSelectedCareer({ ...selectedCareer, [e.target.name]: e.target.value });
    };
  
    const handleEditSubmit = async () => {
      try {
        const response = await fetch(
          `https://kushalmedicals.com/admin/HomeProductEnquiry/editHomeProductEnquiry/${selectedCareer._id}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(selectedCareer),
          }
        );
  
        if (response.ok) {
          Swal.fire("Updated!", "home Product Enquiry application updated successfully.", "success");
          getHomeProductEnquiry(); // Refresh data
          setEditModal(false);
        } else {
          throw new Error("Failed to update");
        }
      } catch (error) {
        Swal.fire("Error", "Failed to update application.", "error");
      }
    };
  
    // Handle Delete
    const handleDelete = async (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to recover this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await fetch(
              `https://kushalmedicals.com/admin/HomeProductEnquiry/deleteHomeProductEnquiry/${id}`,
              { method: "POST" }
            );
  
            if (response.ok) {
              Swal.fire("Deleted!", "home Product Enquiry application has been deleted.", "success");
              getHomeProductEnquiry();
            } else {
              throw new Error("Failed to delete");
            }
          } catch (error) {
            Swal.fire("Error", "Failed to delete application.", "error");
          }
        }
      });
    };
  return (
    <div className="container mt-4">
      <h3 className="text-center text-muted fw-bold">home Product Enquiry Details</h3>

      {/* Bootstrap Table */}
      <div className="table-responsive mt-3">
        <Table striped bordered hover className="shadow-sm">
        <thead className="table-success text-center">
        <tr>
              <th>S. No</th>
              <th>Date</th>
              <th>Name</th>
              <th>Contact No</th>
              <th>Email</th>
              <th>Message</th>
<th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {homeProductEnquiry.map((row,index) => {
               const addedDate = row.createdAt
                  ? dayjs(row.createdAt).format("DD-MM-YYYY")
                  : dayjs().format("DD-MM-YYYY"); 
                  
  return (
              <tr key={row.id}>
                <td>{index + 1}</td>
                <td>{addedDate}</td>
                <td>{row.name}</td>
                <td>{row.contact}</td>
                <td>{row.email}</td>
                <td>{row.message}</td>
               <td>
                                  <Button
                                    variant="outline-success"
                                    className="me-2"
                                    onClick={() => handleEditOpen(row)}
                                  >
                                    <Edit />
                                  </Button>
                                  <Button variant="outline-danger" onClick={() => handleDelete(row._id)}>
                                    <Delete />
                                  </Button>
                        </td>
               </tr>
             );
           })}
           
                     </tbody>
                   </Table>
      </div>

      <Modal show={editModal} onHide={() => setEditModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Home Product Enquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCareer && (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={selectedCareer.name}
                  onChange={handleEditChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Contact No</Form.Label>
                <Form.Control
                  type="text"
                  name="contact"
                  value={selectedCareer.contact}
                  onChange={handleEditChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={selectedCareer.email}
                  onChange={handleEditChange}
                />
              </Form.Group>
        
            
              <Form.Group className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  value={selectedCareer.message}
                  onChange={handleEditChange}
                />
              </Form.Group>
              <Button variant="success" onClick={handleEditSubmit}>
                Save Changes
              </Button>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HomeProductEnquiry;
