import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { MyContext } from "../../context/Context";
import axios from "axios";
import Swal from "sweetalert2";

const AboutMissions = () => {

  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  
  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
  });

  const { PORT, getAboutMission , aboutMission } = useContext(MyContext);

  useEffect(() => {
    getAboutMission();
  }, []);

  // Open & Close Modals
  const handleOpen = () => {
    setFormValues({title: "", description: "" });
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleEditOpen = (index) => {
    setEditIndex(index);
    setFormValues({
      title: aboutMission[index].title,
      description: aboutMission[index].description,
    });
    setEditOpen(true);
  };
  const handleEditClose = () => setEditOpen(false);

  const handleChange = (e) => {
    const { name, type } = e.target;
    if (type === "file") {
      setFormValues({ ...formValues, [name]: e.target.files[0] });
    } else {
      setFormValues({ ...formValues, [name]: e.target.value });
    }
  };

  const handleSubmit = async () => {
    try {
   

      const res = await axios.post(`${PORT}/admin/AboutMission/addAboutMission`, formValues);
      
      if (res.data.message === "About Mission added successfully") {
        Swal.fire("Success", "About Mission added successfully!", "success");
        getAboutMission();
        setOpen(false);
      }
    } catch (error) {
      Swal.fire("Error", "Failed to add Welcome Card!", "error");
    }
  };

  // Handle Edit Welcome Card
  const handleEditSubmit = async () => {
    try {
  
      await axios.post(`${PORT}/admin/AboutMission/editAboutMission/${aboutMission[editIndex]._id}`, formValues);
      
      Swal.fire("Updated!", "About Mission updated successfully!", "success");
      getAboutMission();
      setEditOpen(false);
    } catch (error) {
      Swal.fire("Error", "Failed to update About Mission!", "error");
    }
  };
  

  // Handle Delete Welcome Card
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.post(`${PORT}/admin/AboutMission/deleteAboutMission/${id}`);
          Swal.fire("Deleted!", "About Mission has been deleted.", "success");
          getAboutMission();
        } catch (error) {
          Swal.fire("Error", "Failed to delete About Mission!", "error");
        }
      }
    });
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3 px-4">
        <h3 className="text-muted">Welcome Card</h3>
        <Button variant="success" onClick={handleOpen}>ADD</Button>
      </div>

      {/* Add About Modal */}
      <Modal show={open} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Welcome Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
        
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" name="title" value={formValues.title} onChange={handleChange} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={3} name="description" value={formValues.description} onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="success" onClick={handleSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>

      {/* Display Abouts */}
      <div className="row d-flex justify-content-center">
        {aboutMission?.length > 0 ? (
          aboutMission.map((item, index) => (
            <div key={index} className="col-md-5">
              <div className="card p-3 mb-4 shadow-sm">
                <h5 className="text-center mt-2">{item.title}</h5>
                <p>{item.description}</p>
                <div className="d-flex justify-content-between">
                  <Button variant="outline-success" onClick={() => handleEditOpen(index)}>Edit</Button>
                  <Button variant="outline-danger" onClick={() => handleDelete(item._id)}>Delete</Button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">No Abouts available</p>
        )}
      </div>

      {/* Edit About Modal */}
      <Modal show={editOpen} onHide={handleEditClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Welcome Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
        
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" name="title" value={formValues.title} onChange={handleChange} />
            </Form.Group>
        
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={3} name="description" value={formValues.description} onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>Close</Button>
          <Button variant="success" onClick={handleEditSubmit}>Update</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AboutMissions;
