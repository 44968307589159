import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { MyContext } from "../../context/Context";
import axios from "axios";
import Swal from "sweetalert2";

const SharedLink = () => {

  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  
  const [formValues, setFormValues] = useState({
    whatsapp: "",
    facebook: "",
    email:"",
    twitter:"",
    pinterest:""
  });

  const { PORT, getMediaLinks , mediaLinks } = useContext(MyContext);

  useEffect(() => {
    getMediaLinks();
  }, []);

  // Open & Close Modals
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleEditOpen = (index) => {
    setEditIndex(index);
    setFormValues({
      whatsapp: mediaLinks[index].whatsapp,
      facebook: mediaLinks[index].facebook,
      email: mediaLinks[index].email,
      twitter: mediaLinks[index].twitter,
      pinterest: mediaLinks[index].pinterest,

    });
    setEditOpen(true);
  };
  const handleEditClose = () => setEditOpen(false);

  const handleChange = (e) => {
    const { name, type } = e.target;
    if (type === "file") {
      setFormValues({ ...formValues, [name]: e.target.files[0] });
    } else {
      setFormValues({ ...formValues, [name]: e.target.value });
    }
  };

  const handleSubmit = async () => {
    try {
   

      const res = await axios.post(`${PORT}/admin/MediaLinks/addMediaLinks`, formValues);
      
      if (res.data.message === "Media Links added successfully") {
        Swal.fire("Success", "Media Links added successfully!", "success");
        getMediaLinks();
        setOpen(false);
      }
    } catch (error) {
      Swal.fire("Error", "Failed to add Media Links!", "error");
    }
  };

  // Handle Edit Media Links
  const handleEditSubmit = async () => {
    try {
  
      await axios.post(`${PORT}/admin/MediaLinks/editMediaLinks/${mediaLinks[editIndex]._id}`, formValues);
      
      Swal.fire("Updated!", "Media Links updated successfully!", "success");
      getMediaLinks();
      setEditOpen(false);
    } catch (error) {
      Swal.fire("Error", "Failed to update Media Links!", "error");
    }
  };
  

  // Handle Delete Media Links
  const handleDelete = async (id) => {
    Swal.fire({
      whatsapp: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.post(`${PORT}/admin/MediaLinks/deleteMediaLinks/${id}`);
          Swal.fire("Deleted!", "Media Links has been deleted.", "success");
          getMediaLinks();
        } catch (error) {
          Swal.fire("Error", "Failed to delete Media Links!", "error");
        }
      }
    });
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3 px-4">
        <h3 className="text-muted">Media Links</h3>
        <Button variant="success" onClick={handleOpen}>ADD</Button>
      </div>

      {/* Add About Modal */}
      <Modal show={open} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Media Links</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
        
            <Form.Group>
              <Form.Label>Whatsapp</Form.Label>
              <Form.Control type="text" name="whatsapp" value={formValues.whatsapp} onChange={handleChange} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Facebook</Form.Label>
              <Form.Control type="text" name="facebook" value={formValues.facebook} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control type="text" name="email" value={formValues.email} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Twitter</Form.Label>
              <Form.Control type="text" name="twitter" value={formValues.twitter} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Pinterest</Form.Label>
              <Form.Control type="text" name="pinterest" value={formValues.pinterest} onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="success" onClick={handleSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>

      {/* Display Media Links */}
      <div className="row d-flex justify-content-center">
        {mediaLinks?.length > 0 ? (
          mediaLinks.map((item, index) => (
            <div key={index} className="col-md-5">
              <div className="card p-3 mb-4 shadow-sm">
                <p className="text-justify mt-2"> <h5 className="fw-bold">Whatsapp Link : </h5> {item.whatsapp}</p>
                <p className="text-justify mt-2"><h5 className="fw-bold">Facebook Link :</h5>  {item.facebook}</p>
                <p className="text-justify mt-2"><h5 className="fw-bold">Email Link : </h5>{item.email}</p>
                <p className="text-justify mt-2"><h5 className="fw-bold">Twitter Link : </h5>{item.twitter}</p>
                <p className="text-justify mt-2"><h5 className="fw-bold">Pinterest Link : </h5>{item.pinterest}</p>
                <div className="d-flex justify-content-between">
                  <Button variant="outline-success" onClick={() => handleEditOpen(index)}>Edit</Button>
                  <Button variant="outline-danger" onClick={() => handleDelete(item._id)}>Delete</Button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">No Media Links available</p>
        )}
      </div>

      {/* Edit About Modal */}
      <Modal show={editOpen} onHide={handleEditClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Media Links</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group>
              <Form.Label>Whatsapp</Form.Label>
              <Form.Control type="text" name="whatsapp" value={formValues.whatsapp} onChange={handleChange} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Facebook</Form.Label>
              <Form.Control type="text" name="facebook" value={formValues.facebook} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control type="text" name="email" value={formValues.email} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Twitter</Form.Label>
              <Form.Control type="text" name="twitter" value={formValues.twitter} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Pinterest</Form.Label>
              <Form.Control type="text" name="pinterest" value={formValues.pinterest} onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>Close</Button>
          <Button variant="success" onClick={handleEditSubmit}>Update</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SharedLink;
