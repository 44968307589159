import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

const MyContext = createContext(null);

const MyProvider = ({ children }) => {
  const PORT = "https://kushalmedicals.com";


  const [homeSlider, setHomeSlider] = useState([]);
  const [homeAbout, setHomeAbout] = useState([]);
  const [welcomeCard, setWelcomeCard] = useState([]);
  const [achievement, setAchievement] = useState([]);
  const [ourClients , setOurClients] = useState([])
  const [ourPatient , setOurPatient] = useState([])
  const [aboutBanner , setAboutBanner] = useState([])
  const [aboutHospital , setAboutHospital] =useState([])
  const [aboutMission , setAboutMission] = useState([])
  const [credibility , setCredibility] = useState([])
  const [aboutStaff , setAboutStaff] = useState([])
  const [staffContent , setStaffContent] = useState([])
  const [division , setDivision] = useState([])
  const [career , setCareer] = useState([])
  const [contactUs , setContactUs] = useState([])
  const [contactUsDetails , setContactUsDetails] = useState([])
  const [products , setProducts] = useState([])
  const [productsBanner , setProductsBanner] = useState([])
  const [viewProductsEnquiry , setViewProductsEnquiry] = useState([])
  const [singleProduct , setSingleProduct] = useState([])
  const [wishlistEnquiry , setWishlistEnquiry] = useState([])
  const [homeProductEnquiry , setHomeProductEnquiry] = useState([])
  const [mediaLinks , setMediaLinks] = useState([])
  const [adminLogin , setAdminLogin] = useState([])
  const [homeLogin , setHomeLogin] = useState([])







  const getHomeSliders = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/HomeSlider/getHomeSliders`);
      console.log("getHomeSlider", res);
      setHomeSlider(res.data.sliders);
    } catch (error) {
      console.log(error);
    }
  };

  const getHomeAbout = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/HomeAbout/getHomeAbout`);
      console.log("getHomeAbout Data", res);
      setHomeAbout(res.data.Abouts);
    } catch (error) {
      console.log(error);
    }
  };

  const getWelcomeCard = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/WelcomeCard/getWelcomeCard`);
      console.log("getWelcomeCard Data", res);
      setWelcomeCard(res.data.Welcomes);
    } catch (error) {
      console.log(error);
    }
  };

  const getAchievement = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/Achievement/getAchievement`);
      console.log("getAchievement Data", res);
      setAchievement(res.data.Achievement);
    } catch (error) {
      console.log(error);
    }
  };

  const getOurClients = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/OurClients/getOurClients`);
      console.log("getOurClients Data", res);
      setOurClients(res.data.OurClients);
    } catch (error) {
      console.log(error);
    }
  };

  const getOurPatient = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/OurPatient/getOurPatient`);
      console.log("getOurPatient Data", res);
      setOurPatient(res.data.OurPatients);
    } catch (error) {
      console.log(error);
    }
  };

  const getAboutBanner = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/AboutBanner/getAboutBanner`);
      console.log("getAboutBanner Data", res);
      setAboutBanner(res.data.AboutBanner);
    } catch (error) {
      console.log(error);
    }
  };

  const getAboutHospital = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/AboutHospital/getAboutHospital`);
      console.log("getAboutHospital Data", res);
      setAboutHospital(res.data.aboutHospital);
    } catch (error) {
      console.log(error);
    }
  };

  const getAboutMission = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/AboutMission/getAboutMission`);
      console.log("getAboutMission Data", res);
      setAboutMission(res.data.AboutMission);
    } catch (error) {
      console.log(error);
    }
  };


  const getCredibility = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/Credibility/getCredibility`);
      console.log("getCredibility Data", res);
      setCredibility(res.data.Credibility);
    } catch (error) {
      console.log(error);
    }
  };

  const getAboutStaff = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/AboutStaff/getAboutStaff`);
      console.log("getAboutStaff Data", res);
      setAboutStaff(res.data.AboutStaff);
    } catch (error) {
      console.log(error);
    }
  };

  const getStaffContent = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/StaffContent/getStaffContent`);
      console.log("getStaffContent Data", res);
      setStaffContent(res.data.StaffContent);
    } catch (error) {
      console.log(error);
    }
  };

  const getDivision = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/Division/getDivision`);
      console.log("getDivision Data", res);
      setDivision(res.data.Division);
    } catch (error) {
      console.log(error);
    }
  };

  const getCareer = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/Career/getCareer`);
      console.log("getCareer Data", res);
      setCareer(res.data.Careers);
    } catch (error) {
      console.log(error);
    }
  };

  const getContactUs = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/ContactUs/getContactUs`);
      console.log("getContactUs Data", res);
      setContactUs(res.data.ContactUs);
    } catch (error) {
      console.log(error);
    }
  };

  const getContactUsDetails = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/ContactDetails/getContactUsDetails`);
      console.log("getContactUsDetails Data", res);
      setContactUsDetails(res.data.ContactUsDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const getProducts = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/Products/getProducts`);
      console.log("getProducts Data", res);
      setProducts(res.data.Products);
    } catch (error) {
      console.log(error);
    }
  };

  const getProductsBanner = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/ProductsBanner/getProductsBanner`);
      console.log("getProductsBanner Data", res);
      setProductsBanner(res.data.ProductsBanner);
    } catch (error) {
      console.log(error);
    }
  };

  const getViewProductsEnquiry = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/ViewProducts/getViewProductsEnquiry`);
      console.log("getViewProductsEnquiry Data", res);
      setViewProductsEnquiry(res.data.ViewProductsEnquiry);
    } catch (error) {
      console.log(error);
    }
  };

  const getSingleProduct = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/SingleProduct/getSingleProduct`);
      console.log("getSingleProduct Data", res);
      setSingleProduct(res.data.SingleProduct);
    } catch (error) {
      console.log(error);
    }
  };


  const getWishlistEnquiry = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/WishlistEnquiry/getWishlistEnquiry`);
      console.log("getWishlistEnquiry Data", res);
      setWishlistEnquiry(res.data.WishlistEnquiry);
    } catch (error) {
      console.log(error);
    }
  };

  const getHomeProductEnquiry = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/HomeProductEnquiry/getHomeProductEnquiry`);
      console.log("getHomeProductEnquiry Data", res);
      setHomeProductEnquiry(res.data.HomeProductEnquiry);
    } catch (error) {
      console.log(error);
    }
  };

  const getMediaLinks = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/MediaLinks/getMediaLinks`);
      console.log("getMediaLinks Data", res);
      setMediaLinks(res.data.MediaLinks);
    } catch (error) {
      console.log(error);
    }
  };

  const getAdminLogin = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/AdminLogin/getAdminLogin`);
      console.log("getAdminLogin Data", res);
      setAdminLogin(res.data.AdminLogin);
    } catch (error) {
      console.log(error);
    }
  };

  const getLogin = async () => {
    try {
      const res = await axios.get(`${PORT}/admin/Login/getLogin`);
      console.log("getLogin Data", res);
      setHomeLogin(res.data.homeLogin);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHomeSliders();
    getHomeAbout();
    getWelcomeCard();
    getAchievement();
    getOurClients();
    getOurPatient();
    getAboutBanner();
    getAboutHospital();
    getAboutMission();
    getCredibility();
    getAboutStaff();
    getStaffContent();
    getDivision();
    getCareer();
    getContactUs();
    getContactUsDetails();
    getProducts();
    getProductsBanner();
    getViewProductsEnquiry();
    getSingleProduct();
    getWishlistEnquiry();
    getHomeProductEnquiry();
    getMediaLinks();
    getAdminLogin();
    getLogin();
  }, []);

  return (
    <MyContext.Provider
      value={{
        PORT,
        getHomeSliders,
        homeSlider,
        getHomeAbout,
        homeAbout,
        getWelcomeCard,
        welcomeCard,
        getAchievement,
        achievement,
        getOurClients,
        ourClients,
        ourPatient,
        getOurPatient,
        getAboutBanner,
        aboutBanner,
        getAboutHospital,
        aboutHospital,
        getAboutMission,
        aboutMission,
        getCredibility,
        credibility,
        getAboutStaff,
        aboutStaff,
        getStaffContent,
        staffContent,
        getDivision,
        division,
        getCareer,
        career,
        getContactUs,
        contactUs,
        getContactUsDetails,
        contactUsDetails,
        getProducts,
        products,
        getProductsBanner,
        productsBanner,
        getViewProductsEnquiry,
        viewProductsEnquiry,
        getSingleProduct,
        singleProduct,
        getWishlistEnquiry,
        wishlistEnquiry,
        getHomeProductEnquiry,
        homeProductEnquiry,
        getMediaLinks,
        mediaLinks,
        getAdminLogin,
        adminLogin,
        getLogin,
        homeLogin
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export { MyContext, MyProvider };
