import React, { useContext, useEffect, useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Pagination,
  Rating,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { ShoppingCart } from "lucide-react";
import CloseIcon from "@mui/icons-material/Close";
import { useShopping } from "./ProductContext";
import ProductsBanner from "../Components/Assets/ProductsBanner.jpg";
import { MyContext } from "../context/Context";
import axios from "axios";
import Swal from "sweetalert2";

const Products = () => {
  const {
    PORT,
    getProducts,
    products,
    getProductsBanner,
    productsBanner,
    getViewProductsEnquiry,
    viewProductsEnquiry,
  } = useContext(MyContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    getProducts();
    getProductsBanner();
    getViewProductsEnquiry();
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Get context values
  const { favorites, toggleFavorite, addToCart, wishlistCount } = useShopping();

  // Filter products based on search term
  const filteredProducts = products.filter((product) =>
    product.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const productsPerPage = 8;
  const startIndex = (page - 1) * productsPerPage;
  const displayedProducts = filteredProducts.slice(
    startIndex,
    startIndex + productsPerPage
  );
  const pageCount = Math.ceil(filteredProducts.length / productsPerPage);

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    message: "",
    createdAt: new Date(),
  });

  const [errors, setErrors] = useState({
    email: "",
    contact: "",
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateContact = (contact) => {
    const contactRegex = /^[0-9]{10,}$/;
    return contactRegex.test(contact);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      setErrors({
        ...errors,
        email: validateEmail(value) ? "" : "Invalid email format",
      });
    }

    if (name === "contact") {
      setErrors({
        ...errors,
        contact: validateContact(value)
          ? ""
          : "Invalid contact number (must be at least 10 digits)",
      });
    }
  };

  const handleProductsSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      setErrors({ ...errors, email: "Invalid email format" });
      return;
    }

    if (!validateContact(formData.contact)) {
      setErrors({
        ...errors,
        contact: "Invalid contact number (must be at least 10 digits)",
      });
      return;
    }

    try {
      const response = await axios.post(
        "https://kushalmedicals.com/admin/ViewProducts/addViewProductsEnquiry",
        formData
      );

      console.log("API Response:", response);

      if (
        response.data.message === "View Products Enquiry added successfully"
      ) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "View Products added successfully!",
          confirmButtonColor: "#28a745",
        });
        setSelectedProduct(false);
        setFormData({ name: "", contact: "", email: "", message: "" });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Something went wrong!",
          text: "Server did not return expected response.",
          confirmButtonColor: "#ffc107",
        });
      }
    } catch (error) {
      console.error("API Error:", error); // Debugging

      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to submit View Products Enquiry. Please try again!",
        confirmButtonColor: "#d33",
      });
    }
  };

  return (
    <div style={{ backgroundColor: "#e8e7ea" }}>
      {productsBanner.map((item, index) => (
        <div key={index} className="position-relative">
          <h4
            className="position-absolute top-50 start-50 translate-middle text-white text-center w-100 px-3"
            style={{
              fontFamily: "'Playfair Display', serif",
              fontWeight: "bold",
              fontSize: "clamp(18px, 8vw, 36px)", // Adjusts based on screen width
              textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
              whiteSpace: "nowrap", // Prevents text from breaking into multiple lines
            }}
          >
            PRODUCTS
          </h4>
          <img
            src={`${PORT}/${item.image}`}
            alt="About Banner"
            className="img-fluid w-100"
            style={{ height: "400px", objectFit: "cover" }}
          />
        </div>
      ))}

      <div>
        <div className="container mt-4">
          {/* Search Bar */}
          <div className="container mx-auto p-4">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "2rem",
              }}
            >
              <TextField
                placeholder="Search products..."
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: "60%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  bgcolor: "white",
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent",
                    },
                  },
                }}
              />
            </div>

            {/* Product Grid */}
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "15px",
                justifyContent: "center",
              }}
            >
              {displayedProducts.map((product) => (
                <div
                  key={product.id}
                  onClick={() => navigate(`/product/${product._id}`)}
                  style={{
                    width: "300px",
                    padding: "10px",
                    borderRadius: "10px",
                    textAlign: "center",
                    backgroundColor: "#f9f9f9",
                    cursor: "pointer", // Add pointer cursor for better UX
                  }}
                >
                  <div
                    style={{
                      borderRadius: "8px",
                      transition: "transform 0.3s ease",
                      cursor: "pointer",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.transform = "scale(1.02)")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.transform = "scale(1)")
                    }
                  >
                    <div style={{ position: "relative" }}>
                      <img
                        src={`${PORT}/${product.image}`}
                        alt={product.name}
                        style={{
                          width: "100%",
                          height: "150px",
                          marginTop: "20px",
                          objectFit: "contain",
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px",
                        }}
                      />
                      <IconButton
                        style={{
                          position: "absolute",
                          top: "8px",
                          right: "8px",
                          backgroundColor: "rgb(150,234,212)",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleFavorite(product); // Pass the entire product object
                        }}
                      >
                        <FavoriteIcon
                          sx={{
                            color: favorites.has(product._id) ? "red" : "gray", // Use _id here
                            fontSize: "24px",
                          }}
                        />
                      </IconButton>
                    </div>
                    <div style={{ padding: "1rem" }}>
                      <h3
                        className="text-center"
                        style={{
                          fontSize: "1.1rem",
                          fontWeight: "600",
                          marginBottom: "0.5rem",
                        }}
                      >
                        {product.title}
                      </h3>
                      <div
                        className="text-center"
                        style={{ justifyContent: "space-between" }}
                      >
                        <Rating value={product.star} readOnly size="small" />
                        <h4 className="mb-3 text-muted">
                          Price : ${product.price}
                        </h4>
                      </div>
                      <div className="d-flex justify-content-between">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedProduct(product);
                          }}
                          className="btn btn-outline-success"
                        >
                          VIEW
                        </button>
                        <button 
                          onClick={() => addToCart(product)} 
                          className="btn btn-outline-success"
                        >
                          <ShoppingCart size={16} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Pagination */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <Pagination
                count={pageCount}
                page={page}
                onChange={(_, value) => setPage(value)}
                showFirstButton
                showLastButton
                sx={{
                  bgcolor: "white",
                  borderRadius: "8px",
                  padding: "0.5rem",
                  "& .MuiPaginationItem-root": {
                    margin: "0 4px",
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Product Detail Dialog */}
      <Dialog
        open={!!selectedProduct}
        onClose={() => setSelectedProduct(null)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "12px",
            overflow: "hidden",
          },
        }}
      >
        {selectedProduct && (
          <>
            <DialogTitle
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "black",
                backgroundColor: "rgb(150,234,212)",
              }}
            >
              <Typography
                variant="h6"
                component="div"
                style={{ fontWeight: 600 }}
              >
                {selectedProduct.title}
              </Typography>
              <IconButton
                onClick={() => setSelectedProduct(null)}
                style={{ color: "white" }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box sx={{ p: 2 }}>
                <img
                  src={`${PORT}/${selectedProduct.image}`}
                  alt={selectedProduct.name}
                  style={{
                    width: "100%",
                    height: "400px",
                    objectFit: "contain",
                    borderRadius: "8px",
                    marginBottom: "20px",
                  }}
                />
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {selectedProduct.description}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Rating value={4} readOnly size="large" />
                  <Typography variant="body2" color="text.secondary">
                    4.0 out of 5
                  </Typography>
                  <button 
                    className="btn btn-outline-success" 
                    onClick={() => {
                      addToCart(selectedProduct);
                      setSelectedProduct(null);
                    }}
                  >
                    Add to Cart
                  </button>
                </Box>
              </Box>
              <Box>
                <Typography
                  id="enquiry-modal"
                  variant="h6"
                  component="h2"
                  sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}
                  className="text-muted"
                >
                  Enquiry for : {selectedProduct.title}
                </Typography>
                <form onSubmit={handleProductsSubmit}>
                  {/* Name Input */}
                  <TextField
                    fullWidth
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    label="Name"
                    variant="outlined"
                    sx={{ mb: 2 }}
                  />

                  {/* Email Input */}
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    variant="outlined"
                    sx={{ mb: 2 }}
                  />

                  {/* Mobile Input */}
                  <TextField
                    fullWidth
                    label="Mobile"
                    name="contact"
                    value={formData.contact}
                    onChange={handleChange}
                    error={!!errors.contact}
                    helperText={errors.contact}
                    variant="outlined"
                    sx={{ mb: 2 }}
                  />

                  {/* Message Input */}
                  <TextField
                    fullWidth
                    label="Message"
                    variant="outlined"
                    multiline
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows={3}
                    sx={{ mb: 3 }}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: "rgb(150,234,212)",
                      fontWeight: "bold",
                      "&:hover": { backgroundColor: "rgb(120,204,182)" },
                    }}
                  >
                    Submit
                  </Button>
                </form>
              </Box>
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default Products;
