import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Sidebar from "../Admin/Sidebar";
import Swal from "sweetalert2";
import HomeSlider from "./HomeSlider";
import AboutHome from "./AboutHome";
import WelcomeCard from "./WelcomeCard";
import OurAchievements from "./OurAchievements";
import OurClients from "./OurClients";
import OurPatientsSays from "./OutPatientsSays";
import AboutUsBanner from "./AboutUsBanner";
import AboutUsHospital from "./AboutUsHospital";
import Credibility from "./Credibility";
import OurStaff from "./OurStaff";
import Divisions from "./Divisions";
import HomeProducts from "./Products";
import Career from "./Career";
import ContactUs from "./ContactUs";
import ContactUsDetails from "./ContactUsDetails";
import ViewProductsEnquiry from "./ViewProductsEnquiry";
import SingleProductsEnquiry from "./SingleProductsEnquiry";
import WishlistEnquiry from "./WishlistEnquiry";
import SharedLink from "./SharedLinks";
import AboutMissions from "./AboutMissions";
import StaffContent from "./StaffContent";
import ProductsBanner from "./ProductsBanner";
import HomeProductEnquiry from "./HomeProductEnquiry";

const Admin = () => {
  const [page, setPage] = useState("HOME SLIDER");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const navigate = useNavigate();

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        Swal.fire({
          title: "Logged Out!",
          text: "Redirecting to Login...",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/admin/login");
        });
      }
    });
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const renderContent = () => {
    switch (page) {
      case "HOME SLIDER":
        return <HomeSlider />;
      case "ABOUT":
        return <AboutHome />;
      case "WELCOME CARD":
        return <WelcomeCard />;
      case "OUR ACHIEVEMENTS":
        return <OurAchievements />;
      case "OUR BRANDS":
        return <OurClients />;
      case "PATIENTS SAYS":
        return <OurPatientsSays />;
      case "ABOUT US BANNER":
        return <AboutUsBanner />;
      case "ABOUT US HOSPITAL":
        return <AboutUsHospital />;
      case "ABOUT US MISSION":
        return <AboutMissions />;
      case "CREDIBILITY":
        return <Credibility />;
      case "OUR STAFF":
        return <OurStaff />;
      case "STAFF CONTENT":
        return <StaffContent />;
      case "DIVISIONS":
        return <Divisions />;
      case "PRODUCTS":
        return <HomeProducts />;
      case "PRODUCTS BANNER":
        return <ProductsBanner />;
      case "CAREER":
        return <Career />;
      case "CONTACT US":
        return <ContactUs />;
      case "ADD CONTACT":
        return <ContactUsDetails />;
      case "VIEW PRODUCTS ENQUIRY":
        return <ViewProductsEnquiry />;
      case "SINGLE PRODUCTS ENQUIRY":
        return <SingleProductsEnquiry />;
      case "WISHLIST ENQUIRY":
        return <WishlistEnquiry />;
      case "HOME PRODUCT ENQUIRY":
        return <HomeProductEnquiry />;
      case "MEDIA LINK":
        return <SharedLink />;
      default:
        return <h2>Select an option</h2>;
    }
  };

  return (
    <>
      <div className="wrapper d-flex min-vh-100">
        <Sidebar
          setPage={setPage}
          isOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        />

        <main
          className="flex-grow-1 bg-light"
          style={{
            marginLeft:
              isSidebarOpen && window.innerWidth >= 992 ? "280px" : "0",
            transition: "margin 0.3s ease",
            minHeight: "100vh",
            overflow: "auto",
          }}
        >
          <div className="content-header shadow-sm">
            <div className="container-fluid">
              <div className="row align-items-center py-3">
                <div className="col text-start"></div>
                <div className="col text-end px-4">
                  <button
                    className="btn btn-success text-white"
                    title="Logout"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </div>
                {/* <img
                  style={{ width: "70px", height: "45px" }}
                  src=""
                  alt=""
                  className="rounded-circle me-3"
                /> */}
              </div>
            </div>
          </div>

          <div className="content">
            <div className="container-fluid py-4">
              <div className="row">
                <div className="col-12">{renderContent()}</div>
              </div>
            </div>
          </div>
        </main>

        {isSidebarOpen && window.innerWidth < 992 && (
          <div
            className="position-fixed top-0 start-0 w-100 h-100 bg-dark opacity-50"
            style={{ zIndex: 1030 }}
            onClick={toggleSidebar}
          />
        )}
      </div>
    </>
  );
};

export default Admin;
