import React from "react";
import { Box,Tooltip  } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp"; // Using Material-UI Icon

const WhatsAppButton = () => {
  return (
    <Tooltip title="WhatsApp" arrow placement="left">
    <Box
      component="a"
      href="https://wa.me/919880084362" // Correct format for WhatsApp link
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        position: "fixed",
        bottom: 50,
        right: 20,
        backgroundColor: "#25D366",
        color: "white",
        width: 50,
        height: 50,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
        zIndex: 1000,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#1EBE5D",
        },
      }}
    >
      <WhatsAppIcon sx={{ fontSize: 30 }} />
    </Box>
    </Tooltip>
  );
};

export default WhatsAppButton;
