import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaGlobe } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../Components/Assets/Logo.png";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="text-white py-4" style={{ backgroundColor: "#333" }}>
      <Container>
        <Col md={3} sm={6} className="mb-4">
          <a className="navbar-brand" href="/">
            <img src={Logo} alt="" style={{ width: "300px" }} />
          </a>
        </Col>
        <Row className="text-center text-md-start">
          {/* About Us */}
          <Col md={3} sm={6} className="mb-4">
            <h5 className="fw-bold" style={{ fontSize: "25px" }}>
              ABOUT US
            </h5>
            <p style={{ fontSize: "20px" }}>
              Kushal Medical Devices is a medical B2B online and offline
              platform where you can purchase new medical equipment and
              disposables from manufacturers and distributors worldwide.
            </p>
          </Col>
          <Col md={4} sm={6} className="mb-4">
            <h5 className="fw-bold">CONTACT</h5>
            <p style={{ fontSize: "20px" }}>
              <p>📞 080-32216451 , +91-9880084362</p>
            </p>
            <p style={{ fontSize: "20px" }}>
              <p>📧 kushalmedicaldevices@yahoo.in</p>
            </p>
          </Col>

          <Col md={2} sm={6} className="mb-4">
            <h5 className="fw-bold">ADDRESS</h5>
            <p style={{ fontSize: "20px" }}>
              {/* <FaMapMarkerAlt className="me-2" style={{color:'#bd373d'}} /> */}
              📍 #12, 1st Floor, 5th Cross, Sultan Palaya, R.T. Nagar (Post),
              Bangalore - 560032, India.
            </p>
          </Col>

          <Col md={3} sm={6} className="mb-4">
            <h5 className="fw-bold">WEBSITE</h5>
            <p style={{ fontSize: "20px" }}>📧 info@kushalmedicals.com</p>
            <p style={{ fontSize: "20px" }}>📧 arun@kushalmedicals.com</p>
            <p style={{ fontSize: "20px" }}>📧 kantha@kushalmedicals.com</p>
            <p style={{ fontSize: "20px" }}>
              🌍{" "}
              <a
                href="http://www.kushalmedicals.com"
                className="text-white"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.kushalmedicals.com
              </a>
            </p>
          </Col>

          <div className="d-flex gap-3 justify-content-center">
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="fs-4"
              style={{ color: "#E4405F" }} // Instagram color
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="fs-4"
              style={{ color: "#1877F2" }} // Facebook color
            >
              <FaFacebookF />
            </a>
            <a
              href="https://www.youtube.com"
              target="_blank"
              rel="noopener noreferrer"
              className="fs-4 "
              style={{ color: "#FF0000" }} // YouTube color
            >
              <FaYoutube />
            </a>
          </div>

   
        </Row>
        <div className="text-center mt-4 border-top pt-3">
          <p className="mb-0">
            © 2025 Kushal Medical Devices Developed by{" "}
            <a
              className="text-decoration-none text-white"
              href="https://parnetsgroup.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Parnets
            </a>
          </p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
