import React, { useContext, useEffect, useState } from "react";
import { Table, Modal, Button, Form } from "react-bootstrap";
import { Description, Edit, Delete } from "@mui/icons-material";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { MyContext } from "../../context/Context";

const Career = () => {

  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [selectedCareer, setSelectedCareer] = useState(null);


  const { PORT, getCareer, career } = useContext(MyContext);

  useEffect(() => {
    getCareer();
  }, []);

  const handleOpen = (file) => {
    if (file) {
      setSelectedFile(`${PORT}/${file}`); 
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
  };

  const handleEditOpen = (careerData) => {
    setSelectedCareer({ ...careerData });
    setEditModal(true);
  };

  // Handle Edit Change
  const handleEditChange = (e) => {
    setSelectedCareer({ ...selectedCareer, [e.target.name]: e.target.value });
  };

  const handleEditSubmit = async () => {
    try {
      const response = await fetch(
        `https://kushalmedicals.com/admin/Career/editCareer/${selectedCareer._id}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(selectedCareer),
        }
      );

      if (response.ok) {
        Swal.fire("Updated!", "Career application updated successfully.", "success");
        getCareer(); // Refresh data
        setEditModal(false);
      } else {
        throw new Error("Failed to update");
      }
    } catch (error) {
      Swal.fire("Error", "Failed to update application.", "error");
    }
  };

  // Handle Delete
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to recover this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(
            `https://kushalmedicals.com/admin/Career/deleteCareer/${id}`,
            { method: "POST" }
          );

          if (response.ok) {
            Swal.fire("Deleted!", "Career application has been deleted.", "success");
            getCareer();
          } else {
            throw new Error("Failed to delete");
          }
        } catch (error) {
          Swal.fire("Error", "Failed to delete application.", "error");
        }
      }
    });
  };

  return (
    <div className="container mt-4">
      <h3 className="text-center text-muted fw-bold">Career Applications</h3>

      {/* Bootstrap Table */}
      <div className="table-responsive mt-3">
        <Table striped bordered hover className="shadow-sm">
          <thead className="table-success text-center">
            <tr>
              <th>S. No</th>
              <th>Date</th>
              <th>Name</th>
              <th>Contact No</th>
              <th>Email</th>
              <th>Position</th>
              <th>Experience</th>
              <th>Message</th>
              <th>Resume</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-center">
          {career.map((row, index) => {
  const addedDate = row.createdAt
    ? dayjs(row.createdAt).format("DD-MM-YYYY")
    : dayjs().format("DD-MM-YYYY"); // Use current date if createdAt is missing

  return (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>{addedDate}</td>
      <td>{row.name}</td>
      <td>{row.contact}</td>
      <td>{row.email}</td>
      <td>{row.position}</td>
      <td>{row.experience}</td>
      <td>{row.message}</td>
      <td>
        <Button variant="outline-success" onClick={() => handleOpen(row.resume)}>
          <Description />
        </Button>
      </td>
      <td>
                    <Button
                      variant="outline-success"
                      className="me-2"
                      onClick={() => handleEditOpen(row)}
                    >
                      <Edit />
                    </Button>
                    <Button variant="outline-danger" onClick={() => handleDelete(row._id)}>
                      <Delete />
                    </Button>
                  </td>
    </tr>
  );
})}

          </tbody>
        </Table>
      </div>

      {/* File Preview Modal */}
      <Modal show={open} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Resume Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedFile ? (
            <iframe src={selectedFile} title="Resume" width="100%" height="500px" />
          ) : (
            <p className="text-center text-danger">No file available</p>
          )}
        </Modal.Body>
      </Modal>

      {/* Edit Career Modal */}
      <Modal show={editModal} onHide={() => setEditModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Career Application</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCareer && (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={selectedCareer.name}
                  onChange={handleEditChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Contact No</Form.Label>
                <Form.Control
                  type="text"
                  name="contact"
                  value={selectedCareer.contact}
                  onChange={handleEditChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={selectedCareer.email}
                  onChange={handleEditChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Position</Form.Label>
                <Form.Control
                  type="text"
                  name="position"
                  value={selectedCareer.position}
                  onChange={handleEditChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Experience</Form.Label>
                <Form.Control
                  type="text"
                  name="experience"
                  value={selectedCareer.experience}
                  onChange={handleEditChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  value={selectedCareer.message}
                  onChange={handleEditChange}
                />
              </Form.Group>
              <Button variant="success" onClick={handleEditSubmit}>
                Save Changes
              </Button>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Career;
