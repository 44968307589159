import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { FaArrowLeft, FaShoppingCart, FaHeart } from "react-icons/fa";
import { useShopping } from "./ProductContext";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import {
  useMediaQuery,
  Modal,
  Box,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MyContext } from "../context/Context";
import axios from "axios";
import Swal from "sweetalert2";
import { ShoppingCart } from "lucide-react";

const ProductDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { favorites, toggleFavorite, addToCart } = useShopping();
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);

  const { PORT, getProducts, products } = useContext(MyContext);

  useEffect(() => {
    getProducts();
  }, []);

  const isMobile = useMediaQuery("(max-width: 768px)");

  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");

  const handleShowModal = (productName) => {
    setSelectedProduct(productName);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProduct("");
  };

  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    message: "",
    createdAt: new Date(),
  });

  const [errors, setErrors] = useState({
    email: "",
    contact: "",
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateContact = (contact) => {
    const contactRegex = /^[0-9]{10,}$/;
    return contactRegex.test(contact);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      setErrors({
        ...errors,
        email: validateEmail(value) ? "" : "Invalid email format",
      });
    }

    if (name === "contact") {
      setErrors({
        ...errors,
        contact: validateContact(value)
          ? ""
          : "Invalid contact number (must be at least 10 digits)",
      });
    }
  };

  const handleProductsSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      setErrors({ ...errors, email: "Invalid email format" });
      return;
    }

    if (!validateContact(formData.contact)) {
      setErrors({
        ...errors,
        contact: "Invalid contact number (must be at least 10 digits)",
      });
      return;
    }

    try {
      const response = await axios.post(
        "https://kushalmedicals.com/admin/SingleProduct/addSingleProduct",
        formData
      );

      console.log("API Response:", response);

      if (
        response.data.message === "Single Product Enquiry added successfully"
      ) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Single Product added successfully!",
          confirmButtonColor: "#28a745",
        });
        handleClose();
        setFormData({ name: "", contact: "", email: "", message: "" });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Something went wrong!",
          text: "Server did not return expected response.",
          confirmButtonColor: "#ffc107",
        });
      }
    } catch (error) {
      console.error("API Error:", error); // Debugging

      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to submit Single Product Enquiry. Please try again!",
        confirmButtonColor: "#d33",
      });
    }
  };

  
  // Fetch product and related products
  useEffect(() => {
    window.scrollTo(0, 0);

    // Find product by _id instead of id
    const foundProduct = products.find((p) => p._id === id);

    if (foundProduct) {
      setProduct(foundProduct);

      // Find related products based on category
      const related = products
        .filter(
          (p) =>
            p.category === foundProduct.category && p._id !== foundProduct._id
        )
        .slice(0, 4);

      setRelatedProducts(related);
    }
  }, [id, products]); // Add products to dependency array

  if (!product) {
    return (
      <Container className="d-flex justify-content-center align-items-center min-vh-100">
        <Card className="text-center p-4 shadow-lg">
          <h2 className="text-primary">Loading product...</h2>
          <p>If this persists, the product may not exist.</p>
          <Button variant="success" onClick={() => navigate("/products")}>
            Return to Products
          </Button>
        </Card>
      </Container>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "#e8e7ea",
        minHeight: "100vh",
        padding: "2rem 0",
      }}
    >
      <Container className="py-4">
        {/* Back Button & Title */}
        <div className="d-flex align-items-center mb-4">
          <Link
            to="/products"
            className="btn btn-outline-secondary me-3"
            style={{
              marginTop: isMobile ? "50px" : "",
            }}
          >
            <FaArrowLeft />
          </Link>
        </div>

        {/* Product Details Card */}
        <Card className="shadow-lg p-4">
          <Row className="align-items-center">
            <Col md={6} className="text-center">
              <img
                src={`${PORT}/${product.image}`}
                alt={product.title}
                className="img-fluid w-75"
              />
            </Col>
            <Col md={6}>
              <h1 className="fw-bold text-muted">{product.title}</h1>
              <p className="text-muted">{product.description}</p>
              <h3 className="text-success fw-bold">${product.price}</h3>
              <div className="d-flex gap-3 mt-3 justify-content-md-start justify-content-center">
                <Button
                  variant="light"
                  className="border"
                  onClick={() => toggleFavorite(product)}
                >
                  <FaHeart
                    color={favorites.has(product._id) ? "red" : "gray"}
                  />
                </Button>
                <button
                  className="text-muted btn btn-outline-success"
                  onClick={() => handleShowModal(product.title)}
                >
                  Enquiry
                </button>
                <button 
                          onClick={() => addToCart(product)} 
                          className="btn btn-outline-success"
                        >
                          <ShoppingCart size={16} />
                        </button>
              </div>
            </Col>
          </Row>
        </Card>

        {/* Enquiry Modal */}
        <Modal open={open} aria-labelledby="enquiry-modal">
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "white",
              boxShadow: 24,
              p: 4,
              borderRadius: "10px",
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                color: "gray",
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              id="enquiry-modal"
              variant="h6"
              component="h2"
              sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}
            >
              Enquiry for: {selectedProduct}
            </Typography>

            {/* Input Fields */}
            <form onSubmit={handleProductsSubmit}>
              {/* Name Input */}
              <TextField
                fullWidth
                name="name"
                value={formData.name}
                onChange={handleChange}
                label="Name"
                variant="outlined"
                sx={{ mb: 2 }}
              />

              {/* Email Input */}
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                variant="outlined"
                sx={{ mb: 2 }}
              />

              {/* Mobile Input */}
              <TextField
                fullWidth
                label="Mobile"
                name="contact"
                value={formData.contact}
                onChange={handleChange}
                error={!!errors.contact}
                helperText={errors.contact}
                variant="outlined"
                sx={{ mb: 2 }}
              />

              {/* Message Input */}
              <TextField
                fullWidth
                label="Message"
                variant="outlined"
                multiline
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows={3}
                sx={{ mb: 3 }}
              />

              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "rgb(150,234,212)",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "rgb(120,204,182)" },
                }}
              >
                Submit
              </Button>
            </form>
          </Box>
        </Modal>

        {/* Related Products */}
        <h3 className="mt-5 fw-bold text-muted text-center shadow-lg rounded py-2">
          RELATED PRODUCTS
        </h3>
        <Row className="mt-3 g-4">
          {relatedProducts.length > 0 ? (
            relatedProducts.map((relatedProduct) => (
              <Col key={relatedProduct._id} md={3} sm={6} xs={12}>
                <Card className="shadow-sm border-0 h-100 text-center">
                  <div
                    className="position-relative p-3 bg-light"
                    onClick={() => navigate(`/product/${relatedProduct._id}`)}
                  >
                    <img
                      src={`${PORT}/${relatedProduct.image}`}
                      alt={relatedProduct.title}
                      className="img-fluid w-75"
                    />
                    <Button
                      variant="light"
                      className="position-absolute top-0 end-0 m-2 border"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleFavorite(relatedProduct);
                      }}
                    >
                      <FaHeart
                        color={
                          favorites.has(relatedProduct._id) ? "red" : "gray"
                        }
                      />
                    </Button>
                  </div>
                  <Card.Body>
                    <h5 className="fw-bold text-muted">
                      {relatedProduct.title}
                    </h5>
                    <h6 className="text-success fw-bold">
                      ${relatedProduct.price}
                    </h6>
                    <Button
                      variant="outline-success"
                      className="w-100 mt-2"
                      onClick={() => navigate(`/product/${relatedProduct._id}`)}
                    >
                      View Details
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <div className="text-center py-4">
              <p className="text-muted">No related products found</p>
            </div>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default ProductDetails;
