import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { MyContext } from "../../context/Context";
import axios from "axios";
import Swal from "sweetalert2";

const HomeProducts = () => {
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  
  const [formValues, setFormValues] = useState({
    image: null,
    title: "",
    star: "",
    price:"",
    description:""
  });

  const { PORT, getProducts , products } = useContext(MyContext);

  useEffect(() => {
    getProducts();
  }, []);

  // Open & Close Modals
  const handleOpen = () => {
    setFormValues({ description:"",image: null, title: "", star: "",price:"" });
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleEditOpen = (index) => {
    setEditIndex(index);
    setFormValues({
      title: products[index].title,
      image: products[index].image,
      star: products[index].star,
      price: products[index].price,
      description: products[index].description,


    });
    setEditOpen(true);
  };
  const handleEditClose = () => setEditOpen(false);

  const handleChange = (e) => {
    const { name, type } = e.target;
    if (type === "file") {
      setFormValues({ ...formValues, [name]: e.target.files[0] });
    } else {
      setFormValues({ ...formValues, [name]: e.target.value });
    }
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("title", formValues.title);
      formData.append("star", formValues.star);
      formData.append("price", formValues.price);
      formData.append("description", formValues.description);
      if (formValues.image) formData.append("image", formValues.image);

      const res = await axios.post(`${PORT}/admin/Products/addProducts`, formData);
      
      if (res.data.message === "Products added successfully") {
        Swal.fire("Success", "Products added successfully!", "success");
        getProducts();
        setOpen(false);
      }
    } catch (error) {
      Swal.fire("Error", "Failed to add Products!", "error");
    }
  };

  // Handle Edit Products
  const handleEditSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("title", formValues.title);
      formData.append("star", formValues.star);
      formData.append("price", formValues.price);
      formData.append("description", formValues.description);

      if (formValues.image && formValues.image instanceof File) {
        formData.append("image", formValues.image);
      }
  
      await axios.post(`${PORT}/admin/Products/editProducts/${products[editIndex]._id}`, formData);
      
      Swal.fire("Updated!", "Products updated successfully!", "success");
      getProducts();
      setEditOpen(false);
    } catch (error) {
      Swal.fire("Error", "Failed to update Products!", "error");
    }
  };
  

  // Handle Delete Products
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.post(`${PORT}/admin/Products/deleteProducts/${id}`);
          Swal.fire("Deleted!", "Products has been deleted.", "success");
          getProducts();
        } catch (error) {
          Swal.fire("Error", "Failed to delete Products!", "error");
        }
      }
    });
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3 px-4">
        <h3 className="text-muted">Products</h3>
        <Button variant="success" onClick={handleOpen}>ADD</Button>
      </div>

      {/* Add About Modal */}
      <Modal show={open} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Products</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Upload Products</Form.Label>
              <Form.Control type="file" name="image" onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Price</Form.Label>
              <Form.Control type="number" name="price" value={formValues.price} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" name="title" value={formValues.title} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Star</Form.Label>
              <Form.Control type="number" name="star" onChange={handleChange} />
            </Form.Group>
                <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={3}  name="description" onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="success" onClick={handleSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>

      {/* Display Abouts */}
      <div className="row d-flex justify-content-center">
        {products?.length > 0 ? (
          products.map((item, index) => (
            <div key={index} className="col-md-5">
            <div 
              className="card p-3 mb-4 shadow-sm d-flex flex-column align-items-center text-center"
              style={{ width: "450px", minHeight: "500px", display: "flex", justifyContent: "space-between" }}
            >
              <h4 className="mt-2">{item.price}</h4>
              
              <img 
                src={`${PORT}/${item.image}`} 
                style={{ width: "100%", height: "150px", objectFit: "contain" }} 
                className="img-fluid rounded" 
                alt="Slide" 
              />
          
              <h5 className="mt-2">{item.title}</h5>
              
              {/* Dynamic Stars */}
              <div className="d-flex justify-content-center text-warning">
                {Array.from({ length: item.star }, (_, i) => (
                  <i key={i} className="fas fa-star"></i>
                ))}
              </div>
          
              <h5 className="text-justify mt-2" style={{ overflow: "hidden", textOverflow: "ellipsis", maxHeight: "100px" }}>
                {item.description}
              </h5>
          
              <div className="d-flex justify-content-between w-100">
                <Button variant="outline-success" onClick={() => handleEditOpen(index)}>Edit</Button>
                <Button variant="outline-danger" onClick={() => handleDelete(item._id)}>Delete</Button>
              </div>
            </div>
          </div>
          
          ))
        ) : (
          <p className="text-center">No Abouts available</p>
        )}
      </div>

      {/* Edit About Modal */}
      <Modal show={editOpen} onHide={handleEditClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Products</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Upload Products</Form.Label>
              <Form.Control type="file" name="image" onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" name="title" value={formValues.title} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Price</Form.Label>
              <Form.Control type="number" name="price" value={formValues.price} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Star</Form.Label>
              <Form.Control type="number" name="star" value={formValues.star} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={3}  name="description" value={formValues.description} onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>Close</Button>
          <Button variant="success" onClick={handleEditSubmit}>Update</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HomeProducts;
