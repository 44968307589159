import React, { useState, useEffect, useRef, useContext } from "react";
import { FaStar } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import { MyContext } from "../context/Context";

const Patients = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [direction, setDirection] = useState("next");
  const sliderRef = useRef(null);

  const { PORT, ourPatient, getOurPatient } = useContext(MyContext);

  useEffect(() => {
    AOS.init({ duration: 2000 });
    getOurPatient();
  }, []);

  const handlePrev = () => {
    if (isAnimating || ourPatient.length === 0) return;
    setDirection("prev");
    setIsAnimating(true);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? ourPatient.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    if (isAnimating || ourPatient.length === 0) return;
    setDirection("next");
    setIsAnimating(true);
    setCurrentIndex((prevIndex) =>
      prevIndex === ourPatient.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimating(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [currentIndex]);

  if (ourPatient.length === 0) {
    return <p className="text-center my-5"></p>;
  }

  const currentTestimonial = ourPatient[currentIndex];

  return (
    <div className="container my-5 mb-5" style={{ backgroundColor: "rgb(232,250,251)", borderRadius: "20px" }}>
      <style>
        {`
          .testimonial-container {
            position: relative;
            overflow: hidden;
          }
          .testimonial-slide {
            transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
            opacity: 1;
          }
          .slide-enter {
            opacity: 0;
            transform: translateX(${direction === "next" ? "100px" : "-100px"});
          }
          .slide-exit {
            opacity: 0;
            transform: translateX(${direction === "next" ? "-100px" : "100px"});
          }
          .navigation-btn {
            transition: background-color 0.3s, transform 0.2s;
          }
          .navigation-btn:hover {
            transform: scale(1.05);
          }
          .navigation-btn:active {
            transform: scale(0.95);
          }
        `}
      </style>

      <div className="text-center mb-4">
        <h2 className="fw-bold py-2 text-muted">{currentTestimonial?.title}</h2>
        <div className="d-flex justify-content-center align-items-center">
          <hr style={{ width: "40px", height: "2px", backgroundColor: "#00b3b3", margin: "0 10px" }} />
          <div style={{ color: "#00b3b3", border: "1px solid #00b3b3", width: "30px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>+</div>
          <hr style={{ width: "40px", height: "2px", backgroundColor: "#00b3b3", margin: "0 10px" }} />
        </div>
      </div>

      <div ref={sliderRef} className="testimonial-container">
        <div className={`row mt-5 testimonial-slide ${isAnimating ? "slide-enter" : ""}`} onAnimationEnd={() => setIsAnimating(false)}>
          <div className="col-md-3 text-center">
            <img
              src={`${PORT}/${currentTestimonial?.image}`}
              alt=""
              className="rounded-circle mb-3"
              style={{ width: "150px", height: "150px", objectFit: "cover" }}
              onError={(e) => {
                e.target.src = "https://via.placeholder.com/150";
              }}
            />
            <h4 className="mt-3" style={{ color: "#00b3b3" }}>{currentTestimonial?.name}</h4>
            <p className="text-muted">{currentTestimonial?.designation}</p>
          </div>

          <div className="col-md-9">
            <p className="lead text-secondary mb-4">{currentTestimonial?.description}</p>

            {/* Dynamic Star Ratings */}
            <div className="mb-4 d-flex justify-content-center justify-content-sm-start">
              {Array.from({ length: currentTestimonial?.star || 0 }, (_, i) => (
                <FaStar key={i} style={{ color: "orange", marginRight: "5px" }} />
              ))}
            </div>
          </div>

          <div className="d-flex justify-content-center mb-5">
            <button
              onClick={handlePrev}
              className="btn me-2 navigation-btn"
              style={{ backgroundColor: "#00b3b3", color: "white", width: "80px" }}
              disabled={isAnimating}
            >
              Prev
            </button>
            <button
              onClick={handleNext}
              className="btn navigation-btn"
              style={{ backgroundColor: "#00b3b3", color: "white", width: "80px" }}
              disabled={isAnimating}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Patients;
