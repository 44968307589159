import React, { useEffect, useContext } from "react";
import { Carousel, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Slider1 from "../Components/Assets/Slider1.png";
import Slider2 from "../Components/Assets/Slider2.webp";
import Slider3 from "../Components/Assets/Slider4.png";
import logo from "./Assets/Logo.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Banner2 from "../Components/Assets/Banner2.jpg";
import "../Components/Styles/Home.css";
import { Link } from "react-router-dom";
import Products1 from "../Components/Assets/Prodcuts1.jpg";
import Brands2 from "../Components/Assets/Brands2.jpg";
import Division1 from "../Components/Assets/Division1.jpg";
import doctor from "../Components/Assets/doctor.png";
import Department from "../Components/Assets/Department.png";
import Lab from "../Components/Assets/Lab.png";
import Award from "../Components/Assets/Award.png";
import Patients from "./Patients";
import HomeBanner1 from "../Components/Assets/HomeBanner1.jpg";
import HomeBanner2 from "../Components/Assets/HomeBanner2.jpg";
import HomeBanner from "../Components/Assets/HomeBanner.jpg";
import ProductsSlider from "./ProductsSlider";
import Brands from "./Brands";
import { MyContext } from "../context/Context";
import axios from "axios";

const Home = () => {
  const {
    PORT,
    getHomeSliders,
    homeSlider,
    getHomeAbout,
    homeAbout,
    getWelcomeCard,
    welcomeCard,
    getAchievement,
    achievement,
  } = useContext(MyContext);

  useEffect(() => {
    getHomeSliders();
    getHomeAbout();
    getWelcomeCard();
    getAchievement();
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  

  return (
    <div style={{ backgroundColor: "#e8e7ea", paddingBottom: "15px" }}>
      <div className="container-fluid px-0">
        <div
          id="homeCarousel"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          {/* Carousel Inner (Single container) */}
          <div className="carousel-inner">
            {homeSlider.map((item, index) => (
              <div
                key={index}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
              >
                <img
                  src={`${PORT}/${item.image}`}
                  className="d-block w-100"
                  alt={`Slide ${index + 1}`}
                  style={{ height: "650px" }}
                />
                <div className="carousel-caption d-flex flex-column align-items-center justify-content-end pb-4">
                  <h2
                    style={{
                      fontFamily: "'Playfair Display', serif",
                      fontWeight: "bold",
                      fontSize: "36px",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                    }}
                  >
                    {item.title}
                    {item.logo && ( // Check if logo exists before rendering
                      <span>
                        <img
                          data-aos="zoom-in"
                          src={`${PORT}/${item.logo}`}
                          alt="logo"
                          style={{ width: "350px" }}
                        />
                      </span>
                    )}
                  </h2>
                  <p
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                      fontSize: "18px",
                      maxWidth: "600px",
                      textAlign: "justify",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                    }}
                  >
                    {item.description}
                  </p>
                  <Link to={item.link} className="modern-btn">
                    View More
                  </Link>
                </div>
              </div>
            ))}
          </div>

          {/* Carousel Controls (Outside of .carousel-inner) */}
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#homeCarousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#homeCarousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      </div>

      {/* Right Side - Text with Blur Background */}
      <div className="container-fluid text-black mt-5">
        {homeAbout.map((item, index) => (
          <div  key={index}
          className="row d-flex justify-content-center align-items-center gap-5 w-100">
            <h3 className="text-center fw-bold text-muted py-2 rounded shadow-sm">
            {item.heading}
            </h3>

            <div
              style={{ marginLeft: "15px" }}
              className="col-12 col-md-5 text-center d-flex justify-content-center"
            >
              <img
                src={`${PORT}/${item.image}`}
                alt="Banner"
                data-aos="fade-up"
                className="img-fluid banner-image rounded shadow-lg"
              />
            </div>

            <div
              style={{ marginLeft: "20px" }}
              className="col-12 col-md-6 banner-text p-4 rounded shadow-lg bg-dark banner"
              data-aos="fade-down"
            >
              <h3 className="fw-bold text-white banner-title text-uppercase">
                {item.title}
              </h3>
              <p className="text-justify text-white">{item.description}</p>

              <Link to={item.link}>
                <button className="modern-btn btn text-white px-4 py-2">
                  Read More
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>

      {welcomeCard.map((item, index) => (
        <div
          key={index}
          className="container mt-5 border border-white shadow-lg border-top border-2 border-success p-4"
        >
          <h4 className="text-center fw-bold text-white py-2 rounded shadow-sm banner-title">
            {item.title}
          </h4>
          <p className="text-justify mt-3" style={{ fontFamily: "sans-serif" }}>
            {item.description}
          </p>
        </div>
      ))}

      <ProductsSlider />

      <div className="container mt-4">
          <div  className="row g-3 text-center">
          {achievement.map((item , index) =>(

          <div className="col-md-3 col-6 shadow-lg">

            <div key={index} className="d-flex align-items-center bg-light p-3 rounded shadow flex-column flex-md-row text-md-start">
              <img
                src={`${PORT}/${item.image}`}
                alt="Doctor"
                className="mb-2 mb-md-0 me-md-3"
                style={{ width: "50px", height: "50px" }}
              />
              <div>
                <div className="fw-bold fs-4" style={{ color: "navy" }}>
                 {item.count}
                </div>
                <div style={{ fontSize: "18px" }}>{item.title}</div>
              </div>
            </div>
          
          </div>
         ))}
        </div>
       
        
      </div>
      {/* <Brands /> */}
      <Patients />
    </div>
  );
};

export default Home;
