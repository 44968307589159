import React, { useState } from "react";
import {
  ChevronRight,
  XCircle,
  Home,Info,Handshake ,  Trophy, 
  Users, 
  MessageCircle,
  Image,
  Hospital,
  ShieldCheck,
  UserCheck,
  LayoutGrid,
  Package,
  Briefcase,
  Mail,
  PlusCircle,
  Eye,
  FileText,
  Heart,
  Link
} from "lucide-react";
import { IoMdMenu } from "react-icons/io";
import Logo from "../Assets/Logo.png";

const Sidebar = ({ setPage }) => {
  const [activeItem, setActiveItem] = useState("HOME SLIDER");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

const menuItems = [
  { name: "HOME SLIDER", icon: Home },
  { name: "ABOUT", icon: Info },
  // { name: "WELCOME CARD", icon: Handshake  },
  { name: "OUR ACHIEVEMENTS", icon: Trophy },
  { name: "OUR BRANDS", icon: Users },
  // { name: "PATIENTS SAYS", icon: MessageCircle },
  { name: "ABOUT US BANNER", icon: Image },
  // { name: "ABOUT US HOSPITAL", icon: Hospital },
  // { name: "ABOUT US MISSION", icon: Image },
  { name: "CREDIBILITY", icon: ShieldCheck },
  { name: "OUR STAFF", icon: UserCheck },
  // { name: "STAFF CONTENT", icon: UserCheck },
  { name: "DIVISIONS", icon: LayoutGrid },
  { name: "PRODUCTS", icon: Package },
  { name: "PRODUCTS BANNER", icon: Package },
  { name: "CAREER", icon: Briefcase },
  { name: "CONTACT US", icon: Mail },
  { name: "ADD CONTACT", icon: PlusCircle },
  { name: "VIEW PRODUCTS ENQUIRY", icon: Eye },
  { name: "SINGLE PRODUCTS ENQUIRY", icon: FileText },
  { name: "WISHLIST ENQUIRY", icon: Heart },
  { name: "HOME PRODUCT ENQUIRY", icon: Home },
  { name: "MEDIA LINK", icon: Link }


];

  const handleClick = (pageName) => {
    setActiveItem(pageName);
    setPage(pageName);
    setIsSidebarOpen(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <div
        className="position-fixed d-block d-lg-none "
        style={{
          top: "1rem",
          left: "1rem",
          zIndex: 1030,
        }}
      >
        <button
          className="btn btn-success p-2 rounded-circle"
          onClick={toggleSidebar}
          style={{
            // background: "#48487f",
            border: "none",
            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
          }}
        >
          <IoMdMenu size={24} color="white" />
        </button>
      </div>

      <div className={`${isSidebarOpen ? "show-sidebar" : ""}`}>
        <div
          className={`sidebar position-fixed top-0 start-0 h-100 d-flex flex-column ${
            isSidebarOpen ? "d-block" : "d-none d-lg-flex"
          }`}
          style={{
            width: "280px",
            minHeight: "100vh",
            // background: "#48487f",
            background: 'linear-gradient(to right, #4ac29a, #bdfff3)',
            boxShadow: "4px 0 10px rgba(0,0,0,0.1)",
            zIndex: 1040,
            transition: "transform 0.3s ease-in-out",
            overflowY: "auto",
          }}
        >
          <div className="d-flex justify-content-between align-items-center p-4 border-bottom border-light border-opacity-25">
            <div className="d-flex justify-content-center ">
              <img
                src={Logo}
                alt="Logo"
                width="240"
                height="40"
              />
              {/* <h3 className="text-light fw-bold m-0">Admin</h3> */}
            </div>
            <button
              className="btn text-light d-lg-none"
              onClick={toggleSidebar}
            >
              <XCircle size={24} />
            </button>
          </div>

          <nav className="mt-4 px-3">
            {menuItems.map((item) => {
              const Icon = item.icon;
              const isActive = activeItem === item.name;

              return (
                <button
                  key={item.name}
                  onClick={() => handleClick(item.name)}
                  className={`
                    btn text-start text-muted fw-bold py-3 px-3 mb-2 rounded-3
                    d-flex align-items-center
                    ${isActive ? "active" : ""}
                  `}
                
                >
                  <Icon size={20} className="me-3 fw-bold " />
                  <span className="flex-grow-1">{item.name}</span>
                  {/* <ChevronRight
                    size={16}
                    style={{
                      transform: isActive ? "rotate(90deg)" : "rotate(0)",
                      opacity: isActive ? 1 : 0.5,
                      transition: "transform 0.3s ease",
                    }}
                  /> */}
                </button>
              );
            })}
          </nav>

          <div className="mt-auto p-4 border-top border-light border-opacity-25">
            <p className="text-muted fw-bold m-0">© 2025 Admin Dashboard</p>
          </div>
        </div>

        {isSidebarOpen && (
          <div
            className="position-fixed top-0 start-0 w-100 h-100"
            style={{
              backgroundColor: "rgba(0,0,0,0.5)",
              zIndex: 1035,
            }}
            onClick={toggleSidebar}
          />
        )}
      </div>

      <div className="main-content"></div>
    </>
  );
};

export default Sidebar;
