import React, { useContext, useEffect, useRef, useState } from "react";
import AboutBanner from "../Components/Assets/AboutBanner.jpg";
import AboutUs1 from "../Components/Assets/AboutUs1.png";
import {
  BsArrowRight,
  BsChevronDown,
  BsChevronRight,
  BsPlusCircle,
} from "react-icons/bs";
import { FaBullseye, FaEye, FaLightbulb } from "react-icons/fa";
import "../Components/Styles/AboutUs.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Staff2 from "../Components/Assets/Staff2.jpg";
import experience from "../Components/Assets/experience.png";
import friend from "../Components/Assets/friendliness.png";
import quality from "../Components/Assets/quality.png";
import AboutBanner1 from "../Components/Assets/AboutBanner3.jpg";
import Patients from "./Patients";
import Division from "./Division";
import { MyContext } from "../context/Context";
import { useLocation } from "react-router-dom";

const AboutUs = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const location = useLocation();
  
  useEffect(() => {
    // Check if there's a hash in the URL
    if (location.hash) {
      // Get the element by id (without the # character)
      const element = document.getElementById(location.hash.substring(1));
      
      if (element) {
        // Wait a bit for the page to fully render
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    } else {
      // If no hash, scroll to top (which you're already doing)
      window.scrollTo(0, 0);
    }
  }, [location]);
  


  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const {
    PORT,
    getAboutBanner,
    aboutBanner,
    getAboutHospital,
    aboutHospital,
    getAboutMission,
    aboutMission,
    getCredibility ,
     credibility,
     getAboutStaff, 
     aboutStaff,
     getStaffContent, 
     staffContent,
     getDivision,
      division

  } = useContext(MyContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    getAboutBanner();
    getAboutHospital();
    getAboutMission();
    getCredibility();
    getAboutStaff();
    getStaffContent();
    getDivision();
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "#e8e7ea", paddingBottom: "15px" }}>
        <div className="container-fluid position-relative p-0">
          {aboutBanner.map((item, index) => (
            <div key={index} className="position-relative">
              <h4
                className="position-absolute top-50 start-50 translate-middle text-white text-center w-100 px-3"
                style={{
                  fontFamily: "'Playfair Display', serif",
                  fontWeight: "bold",
                  fontSize: "clamp(18px, 8vw, 36px)",
                  textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                  whiteSpace: "nowrap",
                }}
              >
                ABOUT US
              </h4>
              <img
                src={`${PORT}/${item.image}`}
                alt="About Us Banner"
                className="img-fluid w-100"
                style={{ height: "400px", objectFit: "cover" }}
              />
            </div>
          ))}
        </div>

        <div className="container py-5">
          {aboutHospital.map((item, index) => (
            <div className="row d-flex align-items-start justify-content-center g-5">
              {/* Left Side Image */}
              <div
                key={index}
                className="col-md-6 d-flex justify-content-center h-100"
              >
                <img
                  src={`${PORT}/${item.image}`}
                  alt="KUSHAL MEDICO HOSPITAL"
                  className="img-fluid w-100"
                  style={{
                    height: "500px",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                />
              </div>

              {/* Right Side Content */}
              <div className="col-md-6 px-lg-5 h-100">
                {/* Our Mission */}
                <div className="rounded shadow-sm">
                  <h4 className="text-center fw-bold text-muted py-2 rounded shadow-lg banner-title">
                    {item.title}
                  </h4>
                  <p className="p-3">{item.description}</p>
                </div>

                {aboutMission.map((item, index) => (
                  <div
                    key={index}
                    className="border-bottom py-3"
                    data-aos="zoom-out-down"
                    data-aos-delay="100"
                  >
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleSection(item.title)}
                    >
                      <div className="d-flex align-items-center">
                        {item.title === "Our Mission" ? (
                          <FaBullseye className="me-2 text-dark" />
                        ) : (
                          ""
                        )}
                        {item.title === "Our Vission" ? (
                          <FaEye className="me-2 text-primary" />
                        ) : (
                          ""
                        )}
                        {item.title === "Our Philosophy" ? (
                          <FaLightbulb className="me-2 text-warning" />
                        ) : (
                          ""
                        )}
                        <span className="fw-bold text-dark">{item.title}</span>
                      </div>
                      {openSection === item.title ? (
                        <BsChevronDown className="text-secondary" />
                      ) : (
                        <BsChevronRight className="text-secondary" />
                      )}
                    </div>
                    <div
                      className={`collapse-content ${
                        openSection === item.title ? "open" : ""
                      }`}
                    >
                      <p className="text-secondary mt-2">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div
          className="position-relative text-center text-white py-5"
          style={{
            backgroundImage: `url(${AboutBanner1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            minHeight: "400px", // Ensures it doesn't shrink too much on small screens
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "background-image 1s ease-in-out", // Smooth transition
          }}
        >
          {/* Dark Overlay for Blurred Effect */}
          <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-50"></div>

          {/* Content Section */}
        {credibility.map((item,index)=>(
 <div key={index} className="col-11 col-md-8 col-lg-5 position-relative text-center text-md-start">
 <h4 className="fw-bold" data-aos="fade-right">
 {item.title}
 </h4>
 <p className="text-justify small" data-aos="fade-right">
{item.description}
 </p>

</div>
        ))}
         
        </div>

        <div className="container">
          {aboutStaff.map((item , index)=>(
  <div key={index} className="row d-flex align-items-center justify-content-center gap-5 mt-5">
  {/* Left Side - About Our Staff */}
  <div className="col-12 col-md-5 mx-auto text-justify rounded shadow-sm mb-5 p-3">
    <h4 className="fw-bold text-muted py-2 rounded shadow-lg banner-title">
     {item.title}
    </h4>
    <p className="p-3">
{item.description}
    </p>

    {/* Bottom Three Sections - Experience, Amiability, Reliability */}
    <div className="d-flex flex-column flex-md-row justify-content-center  text-center mt-4 gap-3">
      {staffContent.map((item,index)=>(
      <div>
      <img
        src={`${PORT}/${item.image}`}
        alt="Experience"
        className="img-fluid"
        style={{ width: "50px", height: "50px" }}
      />
      <h5 className="mt-2">{item.title}</h5>
      <p>
        {item.description}
      </p>
    </div>
      ))}


    </div>
  </div>

  {/* Right Side - Staff Image */}
  <div className="col-12 col-md-6 d-flex justify-content-center">
    <img
      src={`${PORT}/${item.image}`}
      alt="Staff"
      className="img-fluid"
      style={{
        maxHeight: "460px",
        objectFit: "cover",
        borderRadius: "10px",
      }}
    />
  </div>
</div>
          ))}
        
        </div>

        <div id="divisions">
  <Division />
</div>        <Patients />
      </div>
    </>
  );
};

export default AboutUs;
