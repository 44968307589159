import React from "react";
import { Navigate, Route, BrowserRouter as Router } from "react-router-dom";
import AppContent from "./AppContent";
import WhatsAppButton from "./Components/WhatsappIcon";
import { ShoppingProvider } from "./Components/ProductContext";

function App() {
  return (
    <Router>
     <ShoppingProvider>
        <AppContent />
      </ShoppingProvider>
    </Router>
  );
}

export default App;
