import React, { useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import AOS from "aos";
import "aos/dist/aos.css";

import { MyContext } from "../context/Context";

const Division = () => {

  const { PORT, getDivision, division } = useContext(MyContext);

  useEffect(() => {
    getDivision();
  }, []);



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <>


        {/* Brands Grid */}
        <div className="container mt-4">
          <h3 className="py-2 rounded shadow-lg text-center fw-bold text-muted ">
            DIVISIONS
          </h3>
          <div className="row g-4 mt-3">
            {division.map((item, index) => (
              <div
                key={index}
                className="col-6 col-md-4 col-lg-3 d-flex justify-content-center"
              >
                <div className="position-relative overflow-hidden rounded-3">
                  <img
                    src={`${PORT}/${item.image}`}
                    alt={`Brand ${index + 1}`}
                    className="img-fluid rounded shadow-sm transition-all"
                    data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                    style={{
                      width: "250px",
                      height: "200px",
                      transition: "transform 0.3s ease-in-out",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.transform = "scale(1.1)")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.transform = "scale(1)")
                    }
                  />
                  <h5 className="text-center">{item.description}</h5>
                </div>
              </div>
            ))}
          </div>
        </div>
    </>
  );
};

export default Division;
