import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { MyContext } from "../../context/Context";
import axios from "axios";
import Swal from "sweetalert2";

const AboutUsHospital  = () => {
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  
  const [formValues, setFormValues] = useState({
    image: null,
    title: "",
    description: "",
  });

  const { PORT, getAboutHospital , aboutHospital } = useContext(MyContext);

  useEffect(() => {
    getAboutHospital();
  }, []);

  // Open & Close Modals
  const handleOpen = () => {
    setFormValues({ image: null, title: "", description: "" });
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleEditOpen = (index) => {
    setEditIndex(index);
    setFormValues({
      title: aboutHospital[index].title,
      description: aboutHospital[index].description,
      image: aboutHospital[index].image,
    });
    setEditOpen(true);
  };
  const handleEditClose = () => setEditOpen(false);

  const handleChange = (e) => {
    const { name, type } = e.target;
    if (type === "file") {
      setFormValues({ ...formValues, [name]: e.target.files[0] });
    } else {
      setFormValues({ ...formValues, [name]: e.target.value });
    }
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("title", formValues.title);
      formData.append("description", formValues.description);
      if (formValues.image) formData.append("image", formValues.image);

      const res = await axios.post(`${PORT}/admin/AboutHospital/addAboutHospital`, formData);
      
      if (res.data.message === "About Hospital added successfully") {
        Swal.fire("Success", "About Hospital added successfully!", "success");
        getAboutHospital();
        setOpen(false);
      }
    } catch (error) {
      Swal.fire("Error", "Failed to add About Hospital!", "error");
    }
  };

  // Handle Edit aboutHospital
  const handleEditSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("title", formValues.title);
      formData.append("description", formValues.description);

      if (formValues.image && formValues.image instanceof File) {
        formData.append("image", formValues.image);
      }
  
      await axios.post(`${PORT}/admin/AboutHospital/editAboutHospital/${aboutHospital[editIndex]._id}`, formData);
      
      Swal.fire("Updated!", "About Hospital updated successfully!", "success");
      getAboutHospital();
      setEditOpen(false);
    } catch (error) {
      Swal.fire("Error", "Failed to update About Hospital!", "error");
    }
  };
  

  // Handle Delete aboutHospital
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.post(`${PORT}/admin/AboutHospital/deleteAboutHospital/${id}`);
          Swal.fire("Deleted!", "About Hospital has been deleted.", "success");
          getAboutHospital();
        } catch (error) {
          Swal.fire("Error", "Failed to delete About Hospital!", "error");
        }
      }
    });
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3 px-4">
        <h3 className="text-muted">About Hospital</h3>
        <Button variant="success" onClick={handleOpen}>ADD</Button>
      </div>

      {/* Add About Modal */}
      <Modal show={open} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New About Hospital</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Upload About image</Form.Label>
              <Form.Control type="file" name="image" onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" name="title" value={formValues.title} onChange={handleChange} />
            </Form.Group>

            <Form.Group>
              <Form.Label>description</Form.Label>
              <Form.Control as="textarea" rows={3} name="description" value={formValues.description} onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="success" onClick={handleSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>

      {/* Display Abouts */}
      <div className="row d-flex justify-content-center">
        {aboutHospital?.length > 0 ? (
          aboutHospital.map((item, index) => (
            <div key={index} className="col-md-5">
              <div className="card p-3 mb-4 shadow-sm">
                <img src={`${PORT}/${item.image}`} className="img-fluid rounded" alt="Slide" />
                <h5 className="text-center mt-2">{item.title}</h5>
                <p>{item.description}</p>
                <div className="d-flex justify-content-between">
                  <Button variant="outline-success" onClick={() => handleEditOpen(index)}>Edit</Button>
                  <Button variant="outline-danger" onClick={() => handleDelete(item._id)}>Delete</Button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">No Abouts available</p>
        )}
      </div>

      {/* Edit About Modal */}
      <Modal show={editOpen} onHide={handleEditClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit About Hospital</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Upload About image</Form.Label>
              <Form.Control type="file" name="image" onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" name="title" value={formValues.title} onChange={handleChange} />
            </Form.Group>
  
            <Form.Group>
              <Form.Label>description</Form.Label>
              <Form.Control as="textarea" rows={3} name="description" value={formValues.description} onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>Close</Button>
          <Button variant="success" onClick={handleEditSubmit}>Update</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AboutUsHospital ;
