import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { TextField, Button, Container, Typography, Box } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import CareerImg1 from "../Components/Assets/CareerImg1.webp";
import axios from "axios";
import Swal from "sweetalert2";

const Career = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    position: "",
    experience: "",
    message: "",
    resume: null,
    createdAt: new Date(),
  });

  const [errors, setErrors] = useState({
    email: "",
    contact: "",
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateContact = (contact) => {
    const contactRegex = /^[0-9]{10,}$/; // Ensures at least 10 digits
    return contactRegex.test(contact);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      setErrors({ ...errors, email: validateEmail(value) ? "" : "Invalid email format" });
    }

    if (name === "contact") {
      setErrors({ ...errors, contact: validateContact(value) ? "" : "Invalid contact number (must be at least 10 digits)" });
    }
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, resume: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      setErrors({ ...errors, email: "Invalid email format" });
      return;
    }

    if (!validateContact(formData.contact)) {
      setErrors({ ...errors, contact: "Invalid contact number (must be at least 10 digits)" });
      return;
    }

    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    try {
      const response = await axios.post("https://kushalmedicals.com/admin/Career/addCareer", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data.message === "Career added successfully") {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Application submitted successfully!",
          confirmButtonColor: "#28a745",
        });

        setFormData({ name: "", contact: "", email: "", position: "", experience: "", message: "", resume: null });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to submit application. Please try again!",
        confirmButtonColor: "#d33",
      });
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${CareerImg1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: "blur(3px)",
        padding: "20px",
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
        },
      }}
    >
      <Container maxWidth="sm" sx={{ position: "relative", zIndex: 2, display: "flex", justifyContent: "center", alignItems: "center", py: { xs: 4, sm: 8 }, mt: { xs: 8, sm: 8 } }}>
        <Box data-aos="zoom-in" sx={{ padding: { xs: "1.5rem", sm: "2rem" }, borderRadius: "12px", textAlign: "center", bgcolor: "#d5e7f1", boxShadow: "0px 4px 20px rgba(16, 4, 4, 0.95)", width: "100%", maxWidth: { xs: "95%", sm: "80%", md: "600px" } }}>
          <Typography className="py-2 rounded shadow-lg text-center text-muted mb-3" sx={{ color: "white", fontWeight: "bold", fontSize: { xs: "18px", sm: "22px" }, padding: "15px", borderRadius: "8px", display: "inline-block", textAlign: "center" }}>CAREER</Typography>

          <form onSubmit={handleSubmit}>
            <TextField fullWidth label="Name" name="name" value={formData.name} onChange={handleChange} variant="standard" sx={{ mb: 2 }} required />
            <TextField fullWidth label="Contact No" name="contact" value={formData.contact} onChange={handleChange} variant="standard" sx={{ mb: 2 }} error={!!errors.contact} helperText={errors.contact} required />
            <TextField fullWidth label="Email" name="email" value={formData.email} onChange={handleChange} variant="standard" sx={{ mb: 2 }} error={!!errors.email} helperText={errors.email} required />
            <TextField fullWidth label="Position" name="position" value={formData.position} onChange={handleChange} variant="standard" sx={{ mb: 2 }} required />
            <TextField fullWidth label="Experience" name="experience" value={formData.experience} onChange={handleChange} variant="standard" sx={{ mb: 2 }} required />
            <TextField fullWidth label="Message" name="message" value={formData.message} onChange={handleChange} variant="standard" multiline rows={3} sx={{ mb: 3 }} />
            <input type="file" accept="application/pdf" onChange={handleFileChange} required />
            <Button type="submit" variant="outlined" size="large" fullWidth sx={{ borderRadius: "8px", textTransform: "none", fontSize: "16px", fontWeight: "bold", color: "#000", borderColor: "#000", mt: 2, "&:hover": { backgroundColor: "#000", color: "#fff" } }}>Submit</Button>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default Career;
