import React, { useContext, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Card,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Logo from "../Components/Assets/Logo.png";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MyContext } from "../context/Context";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const { PORT, getLogin, homeLogin } = useContext(MyContext);

  // Detect mobile screens (max-width: 600px)
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${PORT}/admin/Login/homeLogin`, {
        email,
        password,
      });

      if (response.data.message === "User successfully logged in") {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user)); 
        window.location.reload(); 
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "User successfully logged in",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/");
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error logging in user",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Login Failed",
        text:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#e8e7ea",
        // background: 'linear-gradient(135deg,rgba(232, 231, 234, 0.9) 0%, #e8e7ea 100%)',
        padding: 2,
      }}
    >
      <Card
        sx={{
          width: isMobile ? "90%" : "100%",
          marginTop: isMobile ? "30px" : "",
          maxWidth: 400,
          borderRadius: 4,
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
          background:
            "linear-gradient(135deg, #00b3b3 0%,rgb(165, 226, 239) 100%)",
          padding: isMobile ? 3 : 4,
          textAlign: "center",
          color: "white",
        }}
      >
        <img
          src={Logo}
          alt="Logo"
          style={{
            width: isMobile ? "100%" : "300px",
            marginBottom: isMobile ? "10px" : "20px",
          }}
        />

        <Box component="form" sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="email"
            name="email"
            autoComplete="email"
            autoFocus
            variant="filled"
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlineIcon sx={{ color: "white" }} />
                </InputAdornment>
              ),
            }}
            sx={{
              mb: 2,
              backgroundColor: "#00b3b3",
              borderRadius: 1,
              input: { color: "white" },
              "& .MuiInputLabel-root": { color: "rgba(255, 255, 255, 0.7)" },
              "& .MuiFilledInput-root": {
                backgroundColor: "#00b3b3",
                "&:hover": { backgroundColor: "#00b3b3" },
                "&.Mui-focused": { backgroundColor: "#00b3b3" },
              },
            }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            variant="filled"
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon sx={{ color: "white" }} />
                </InputAdornment>
              ),
            }}
            sx={{
              mb: 2,
              backgroundColor: "#00b3b3",
              borderRadius: 1,
              input: { color: "white" },
              "& .MuiInputLabel-root": { color: "rgba(255, 255, 255, 0.7)" },
              "& .MuiFilledInput-root": {
                backgroundColor: "#00b3b3",
                "&:hover": { backgroundColor: "#00b3b3" },
                "&.Mui-focused": { backgroundColor: "#00b3b3" },
              },
            }}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  sx={{ color: "white", "&.Mui-checked": { color: "white" } }}
                />
              }
              label={
                <Typography variant="body2" sx={{ color: "white" }}>
                  Remember me
                </Typography>
              }
            />
            <Link
              href="#"
              variant="body2"
              style={{ color: "white", textDecoration: "none" }}
            >
              Forgot Password?
            </Link>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <Typography variant="body2">
              Don't have an account?
              <Link
                to="/register"
                style={{ color: "white", textDecoration: "none" }}
              >
                {" "}
                Register
              </Link>
            </Typography>
          </Box>

          <Button
            fullWidth
            className="modern-btn"
            variant="contained"
            onClick={handleLogin}
            sx={{
              mt: 1,
              mb: 2,
              py: isMobile ? 1.2 : 1.5,
              // backgroundColor: '#0a4c8b',
              "&:hover": { backgroundColor: "#083968" },
              borderRadius: 1,
              fontWeight: "bold",
              fontSize: isMobile ? "1rem" : "1.1rem",
            }}
          >
            LOGIN
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default Login;
