import React, { useContext, useEffect, useState } from 'react';
import { 
  IconButton, 
  Card,
  CardContent,
  CardMedia,
  Rating,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Modal, Box, TextField, Typography
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useShopping } from "./ProductContext";
import { FaWhatsapp, FaFacebook, FaEnvelope, FaTwitter, FaPinterest } from "react-icons/fa";
import { MyContext } from '../context/Context';
import axios from 'axios';
import Swal from 'sweetalert2';


const Wishlist = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  
  const { PORT, getProducts , products ,getMediaLinks , mediaLinks} = useContext(MyContext);

  useEffect(() => {
    getProducts();
    getMediaLinks();
  }, []);

  // Use the shopping context for wishlist and cart functionality
  const { 
    wishlistItems, 
    toggleFavorite, 
    addToCart 
  } = useShopping();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const removeFromWishlist = (product) => {
    // Use the toggleFavorite function from context
    toggleFavorite(product);
  };

  const handleAddToCart = (product) => {
    // Use the addToCart function from context
    addToCart(product);
  };

  const navigate = useNavigate(); 

  const [open, setOpen] = useState(false);

  // Open Modal
  const handleOpen = () => setOpen(true);

  // Close Modal
  const handleClose = () => setOpen(false);


  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    message: "",
    createdAt: new Date(),
  });

  const [errors, setErrors] = useState({
    email: "",
    contact: "",
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateContact = (contact) => {
    const contactRegex = /^[0-9]{10,}$/;
    return contactRegex.test(contact);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      setErrors({
        ...errors,
        email: validateEmail(value) ? "" : "Invalid email format",
      });
    }

    if (name === "contact") {
      setErrors({
        ...errors,
        contact: validateContact(value)
          ? ""
          : "Invalid contact number (must be at least 10 digits)",
      });
    }
  };

  const handleProductsSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      setErrors({ ...errors, email: "Invalid email format" });
      return;
    }

    if (!validateContact(formData.contact)) {
      setErrors({
        ...errors,
        contact: "Invalid contact number (must be at least 10 digits)",
      });
      return;
    }

    try {
      const response = await axios.post(
        "https://kushalmedicals.com/admin/WishlistEnquiry/addWishlistEnquiry",
        formData
      );

      console.log("API Response:", response);

      if (
        response.data.message === "Wishlist Enquiry added successfully"
      ) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Wishlist Enquiry added successfully!",
          confirmButtonColor: "#28a745",
        });
        handleClose();
        setFormData({ name: "", contact: "", email: "", message: "" });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Something went wrong!",
          text: "Server did not return expected response.",
          confirmButtonColor: "#ffc107",
        });
      }
    } catch (error) {
      console.error("API Error:", error); 

      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to submit Wishlist Enquiry Enquiry. Please try again!",
        confirmButtonColor: "#d33",
      });
    }
  };

  
  
  return (
    <>
    <div style={{ backgroundColor: "#e8e7ea", minHeight: "100vh", padding: "2rem 0" }}>
      <div className="container">
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 4, mt: 7 }}>
          <Link to="/products" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Button 
              startIcon={<ArrowBackIcon />} 
              sx={{ 
                mr: 2, 
                backgroundColor: "rgb(150,234,212)", 
                color: "black",
                '&:hover': { backgroundColor: "rgb(120,204,182)" }
              }}
            />
          </Link>
          <Typography variant="h4" className='text-muted' component="h1" sx={{ fontWeight: 'bold' }}>
            My Wishlist
          </Typography>
          <FavoriteIcon sx={{ ml: 2, color: 'red' }} />
       

        
        </Box>
      

        {wishlistItems.length === 0 ? (
          <Card sx={{ p: 4, textAlign: 'center', backgroundColor: 'white', borderRadius: '8px' }}>
            <Typography variant="h6">Your wishlist is empty</Typography>
            <Typography variant="body1" sx={{ mt: 2, mb: 3 }}>
              Add items to your wishlist by clicking the heart icon on products.
            </Typography>
            <Link to="/products" style={{ textDecoration: 'none' }}>
              <Button 
                variant="contained" 
                sx={{ 
                  backgroundColor: "rgb(150,234,212)", 
                  color: "black",
                  '&:hover': { backgroundColor: "rgb(120,204,182)" }
                }}
              >
                Browse Products
              </Button>
            </Link>
          </Card>
        ) : (
          <Box sx={{ 
            display: "flex", 
            flexWrap: "wrap", 
            justifyContent: "center", 
            gap: "15px",
            padding: "10px"
          }}>
            {wishlistItems.map((product) => (
              <Box key={product.id} sx={{
                width: { xs: "100%", sm: "48%", md: "32%" }, // Responsive width
                maxWidth: "300px",
                padding: "10px",
                borderRadius: "10px",
                textAlign: "center",
              }}  onClick={() => navigate(`/product/${product._id}`)} 
              >
                <Card 
                  sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    borderRadius: '8px',
                    transition: 'transform 0.3s ease',
                    '&:hover': { transform: 'scale(1.02)' }
                  }}
                >
                  <Box sx={{ position: 'relative' }}>
                    <CardMedia
                      component="img"
                      height="250"
                      image={`${PORT}/${product.image}`}
                      alt={product.name}
                      sx={{ objectFit: 'contain', p: 2 }}
                    />
                    <IconButton
                      onClick={() => removeFromWishlist(product)}
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: "rgb(150,234,212)",
                        '&:hover': { backgroundColor: "rgb(120,204,182)" }
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Box>
                  <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                    <Typography variant="h6" component="div" gutterBottom>
                      {product.title}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                      <Rating value={4} readOnly size="small" />
                    </Box>
                    <Button 
                      variant="outlined"
                      onClick={() => setSelectedProduct(product)}
                      sx={{ 
                        mt: 1,
                        borderColor: "rgb(150,234,212)", 
                        color: "black",
                        '&:hover': { 
                          borderColor: "rgb(120,204,182)",
                          backgroundColor: "rgb(240,250,245)" 
                        }
                      }}
                    >
                      View Details
                    </Button>
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Box>
        )}

        <Dialog
          open={!!selectedProduct}
          onClose={() => setSelectedProduct(null)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            style: {
              borderRadius: '12px',
              overflow: 'hidden'
            }
          }}
        >
          {selectedProduct && (
            <>
              <DialogTitle
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  color: 'black',
                  backgroundColor:"rgb(150,234,212)"
                }}
              >
                <Typography variant="h6" component="div" style={{ fontWeight: 600 }}>
                  {selectedProduct.name}
                </Typography>
                <IconButton
                  onClick={() => setSelectedProduct(null)}
                  style={{ color: 'white' }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Box sx={{ p: 2 }}>
                  <img
                    src={`${PORT}/${selectedProduct.image}`}
                    alt={selectedProduct.name}
                    style={{
                      width: '100%',
                      height: '400px',
                      objectFit: 'contain',
                      borderRadius: '8px',
                      marginBottom: '20px'
                    }}
                  />
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {selectedProduct.description}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Rating value={4} readOnly size="large" />
                    <Typography variant="body2" color="text.secondary">
                      4.0 out of 5
                    </Typography>
                    <button 
                      className="btn btn-outline-success" 
                      onClick={() => {
                        handleAddToCart(selectedProduct);
                        setSelectedProduct(null);
                      }}
                    >
                      Add to Cart
                    </button>
                  </Box>
                </Box>
              </DialogContent>
            </>
          )}
        </Dialog>

      </div>


    </div>
            
<div className="mt-3 mb-4 container ">
  <h4 className='text-muted'>Share On</h4>
{mediaLinks.map((item,index)=>(
 <div key={index} className=" d-flex gap-3">
 <a href={item.whatsapp} target="_blank" rel="noopener noreferrer">
   <FaWhatsapp size={25} color="green" />
 </a>
 <a href={item.facebook} target="_blank" rel="noopener noreferrer">
   <FaFacebook size={25} color="#1877F2" />
 </a>
 <a href={item.email} target="_blank" rel="noopener noreferrer">
   <FaEnvelope size={25} color="#D44638" />
 </a>
 <a href={item.twitter} target="_blank" rel="noopener noreferrer">
   <FaTwitter size={25} color="#1DA1F2" />
 </a>
 <a href={item.pinterest} target="_blank" rel="noopener noreferrer">
   <FaPinterest size={25} color="#E60023" />
 </a>
</div>
))}
 

  <Box
        >
          <Typography
            id="enquiry-modal"
            variant="h6"
            component="h2"
            sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}
            className='text-muted mt-5'
          >
            Enquiry Now
          </Typography>

          {/* Name Input */}
          <form onSubmit={handleProductsSubmit}>
              {/* Name Input */}
              <TextField
                fullWidth
                name="name"
                value={formData.name}
                onChange={handleChange}
                label="Name"
                variant="outlined"
                sx={{ mb: 2 }}
              />

              {/* Email Input */}
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                variant="outlined"
                sx={{ mb: 2 }}
              />

              {/* Mobile Input */}
              <TextField
                fullWidth
                label="Mobile"
                name="contact"
                value={formData.contact}
                onChange={handleChange}
                error={!!errors.contact}
                helperText={errors.contact}
                variant="outlined"
                sx={{ mb: 2 }}
              />

              {/* Message Input */}
              <TextField
                fullWidth
                label="Message"
                variant="outlined"
                multiline
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows={3}
                sx={{ mb: 3 }}
              />

              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "rgb(150,234,212)",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "rgb(120,204,182)" },
                }}
              >
                Submit
              </Button>
            </form>
        </Box>
</div>
</>
  );
}

export default Wishlist;