import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./Components/Navbar";
import AboutUs from "./Components/AboutUs";
import Products from "./Components/Products";
import Career from "./Components/Career";
import ContactUs from "./Components/ContactUs";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import Login from "./Components/Login";
import Register from "./Components/Register";
import Wishlist from "./Components/Wishlist";
import ProductDetails from "./Components/ProductsDetails";
import Admin from "./Components/Admin/Admin";
import AdminLogin from "./Components/Admin/AdminLogin";
import HomeSlider from "./Components/Admin/HomeSlider"; 
import ProtectedRoute from "./Components/Admin/ProtectRoute";
import WhatsAppButton from "./Components/WhatsappIcon";    
import Brands from "./Components/Brands"

const AppContent = () => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith("/admin");

  return (
    <>
      {!isAdminRoute && <Navbar />}

      <Routes>
        <Route path="/" element={<Home /> }/>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/products" element={<Products />} />
        <Route path="/product/:id" element={<ProductDetails />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/wishlist" element={<Wishlist />} />  
        <Route path="/Brands" element={<Brands />} />

        <Route path="/admin/login" element={<AdminLogin />} />

        {/* Protected Admin Routes */}
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/home-slider"
          element={
            <ProtectedRoute>
              <HomeSlider />
            </ProtectedRoute>
          }
        />

      </Routes>

      {!isAdminRoute && <Footer />}
      {!isAdminRoute && <WhatsAppButton/>}
    </>
  );
};

export default AppContent;
