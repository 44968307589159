import React, { createContext, useState, useEffect, useContext } from 'react';
import { MyContext } from '../context/Context';

// Create context 
export const ShoppingContext = createContext();


// Create provider component 
export const ShoppingProvider = ({ children }) => {

  const { PORT, getProducts , products } = useContext(MyContext);

useEffect(() => {
  getProducts();
}, []);

const [favorites, setFavorites] = useState(new Set());
const [wishlistItems, setWishlistItems] = useState([]);
const [cart, setCart] = useState([]);
const [showCart, setShowCart] = useState(false);
const [checkoutStep, setCheckoutStep] = useState(0);

// Load data from localStorage on initial mount
useEffect(() => {
  const storedWishlist = localStorage.getItem("wishlist");
  if (storedWishlist) {
    try {
      const wishlistData = JSON.parse(storedWishlist);
      if (Array.isArray(wishlistData)) {
        setFavorites(new Set(wishlistData.map((item) => item.id)));
        setWishlistItems(wishlistData);
      } else {
        setFavorites(new Set());
        setWishlistItems([]);
        localStorage.setItem("wishlist", JSON.stringify([]));
      }
    } catch (error) {
      console.error("Error parsing wishlist data:", error);
      setFavorites(new Set());
      setWishlistItems([]);
      localStorage.setItem("wishlist", JSON.stringify([]));
    }
  }
}, []);

// Toggle favorites/wishlist
const toggleFavorite = (product) => {
  let updatedWishlistItems = [...wishlistItems];
  const newFavorites = new Set(favorites);

  if (newFavorites.has(product._id)) {  // Change from product.id to product._id
    newFavorites.delete(product._id);
    updatedWishlistItems = updatedWishlistItems.filter((item) => item._id !== product._id);
  } else {
    newFavorites.add(product._id);
    if (!updatedWishlistItems.some((item) => item._id === product._id)) {
      updatedWishlistItems.push(product);
    }
  }

  setFavorites(newFavorites);
  setWishlistItems(updatedWishlistItems);

  localStorage.setItem("wishlist", JSON.stringify(updatedWishlistItems));
};

const wishlistCount = wishlistItems.length; 


  // Cart functions
  const addToCart = (product) => {
    const existingItem = cart.find(item => item._id === product._id);
  
    let updatedCart;
    if (existingItem) {
      updatedCart = cart.map(item => 
        item._id === product._id ? {...item, quantity: item.quantity + 1} : item
      );
    } else {
      updatedCart = [...cart, {...product, quantity: 1}];
    }
  
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    setShowCart(true);
  };
  

  // Listen for storage events (when localStorage changes in other tabs)
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'cart') {
        try {
          const updatedCart = JSON.parse(e.newValue || '[]');
          if (Array.isArray(updatedCart)) {
            setCart(updatedCart);
          }
        } catch (error) {
          console.error('Error parsing cart from localStorage:', error);
        }
      } else if (e.key === 'wishlist') {
        try {
          const wishlistData = JSON.parse(e.newValue || '[]');
          if (Array.isArray(wishlistData)) {
            setFavorites(new Set(wishlistData.map(item => item.id)));
            setWishlistItems(wishlistData);
          }
        } catch (error) {
          console.error('Error parsing wishlist from localStorage:', error);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const updateQuantity = (productId, newQuantity) => {
    if (newQuantity < 1) return;
  
    const updatedCart = cart.map(item =>
      item._id === productId ? {...item, quantity: newQuantity} : item
    );
  
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };
  

  const removeFromCart = (productId) => {
    const updatedCart = cart.filter(item => item._id !== productId);
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };
  
  // Calculate cart count and total price
  const cartCount = cart.reduce((total, item) => total + item.quantity, 0);
  const totalPrice = cart.reduce((total, item) => total + (item.price * item.quantity), 0);

  // Values to provide
  const value = {
    favorites,
    wishlistItems,
    wishlistCount,
    cart,
    showCart,
    setShowCart,
    checkoutStep,
    setCheckoutStep,
    toggleFavorite,
    addToCart,
    updateQuantity,
    removeFromCart,
    cartCount,
    totalPrice
  };

  return (
    <ShoppingContext.Provider value={value}>
      {children}
    </ShoppingContext.Provider>
  );
};

// Custom hook to use the shopping context 
export const useShopping = () => {
  const context = useContext(ShoppingContext);
  if (context === undefined) {
    throw new Error('useShopping must be used within a ShoppingProvider');
  }
  return context;
};