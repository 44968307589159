import React, { useState } from 'react';
import { 
  Box, 
  TextField, 
  Button, 
  Checkbox, 
  FormControlLabel, 
  Typography, 
//   Container, 
  Card, 
//   IconButton, 
  InputAdornment,
  useMediaQuery

} from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Logo from "../Components/Assets/Logo.png"
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';


const Register = () => {


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name , setName] = useState("")
    const [mobile , setMobile] = useState("")


    const navigate = useNavigate();
  
  
    // Detect mobile screens (max-width: 600px)
    const isMobile = useMediaQuery("(max-width:600px)");
  
    const handleRegister = async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post("https://kushalmedicals.com/admin/Login/homeRegister", {
          email,
          password,
          name,
          mobile
        });
  
        if (response.data.message === "User successfully Registered") {
          localStorage.setItem("token", response.data.token);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "User successfully Registered",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/login");
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Error logging in user",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text:
            error.response?.data?.message ||
            "An error occurred. Please try again.",
        });
      }
    };


  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor:'#e8e7ea',
        // background: 'linear-gradient(135deg, #0d2e4a 0%, #1a6ba0 100%)',
        padding: 2
      }}
    >
      <Card
        sx={{
            width: isMobile ? '90%' : '100%',
            marginTop:isMobile ? '100px' : '',
          maxWidth: 400,
          borderRadius: 4,
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
          background: 'linear-gradient(135deg, #00b3b3 0%,rgb(165, 226, 239) 100%)',
          padding: 4,
          textAlign: 'center',
          position: 'relative',
          color: 'white'
        }}
      >

            <img src={Logo} alt="" style={{ width: isMobile ? '100%' : '300px', 
}}/>

        <Box component="form" sx={{ mt: 1 }}>
        <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Name"
            name="name"
            onChange={(e) => setName(e.target.value)}
            autoComplete="Name"
            autoFocus
            variant="filled"
            placeholder="Name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlineIcon sx={{ color: 'white' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              mb: 2,
              backgroundColor: '#00b3b3',
              borderRadius: 1,
              input: { color: 'white' },
              '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
              '& .MuiFilledInput-root': { 
                backgroundColor: '#00b3b3',
                '&:hover': { backgroundColor: '#00b3b3' },
                '&.Mui-focused': { backgroundColor: '#00b3b3' }
              }
            }}
          />
            <TextField
            margin="normal"
            required
            fullWidth
            id="MobileNo"
            label="Mobile No"
            name="mobile"
            onChange={(e) => setMobile(e.target.value)}
            autoComplete="Mobile No"
            autoFocus
            variant="filled"
            placeholder="Mobile No"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlineIcon sx={{ color: 'white' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              mb: 2,
              backgroundColor: '#00b3b3',
              borderRadius: 1,
              input: { color: 'white' },
              '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
              '& .MuiFilledInput-root': { 
                backgroundColor: '#00b3b3',
                '&:hover': { backgroundColor: '#00b3b3' },
                '&.Mui-focused': { backgroundColor: '#00b3b3' }
              }
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="Email"
            label="Email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="Email"
            autoFocus
            variant="filled"
            placeholder="Email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlineIcon sx={{ color: 'white' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              mb: 2,
              backgroundColor: '#00b3b3',
              borderRadius: 1,
              input: { color: 'white' },
              '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
              '& .MuiFilledInput-root': { 
                backgroundColor: '#00b3b3',
                '&:hover': { backgroundColor: '#00b3b3' },
                '&.Mui-focused': { backgroundColor: '#00b3b3' }
              }
            }}
          />
          
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
            variant="filled"
            placeholder="••••••••••••"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon sx={{ color: 'white' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              mb: 2,
              backgroundColor: '#00b3b3',
              borderRadius: 1,
              input: { color: 'white' },
              '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
              '& .MuiFilledInput-root': { 
                backgroundColor: '#00b3b3',
                '&:hover': { backgroundColor: '#00b3b3' },
                '&.Mui-focused': { backgroundColor: '#00b3b3' }
              }
            }}
          />
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <FormControlLabel
              control={<Checkbox value="remember" sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />}
              label={<Typography variant="body2" sx={{ color: 'white' }}>Remember me</Typography>}
            />
            <Link href="#" variant="body2" style={{ color: "white", textDecoration: "none" }} >
              Forgot Password?
            </Link>
          </Box>
          
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                                    <Typography>
            
           Already have an account ?  <Link style={{ color: "white", textDecoration: "none" }} to="/login">
          Login
          
        </Link>
        </Typography>

          </Box>
          <Button
            type="submit"
            fullWidth
            className='modern-btn'
            variant="contained"
            onClick={handleRegister}
            sx={{
              mt: 1,
              mb: 2,
              py: 1.5,
              // backgroundColor: '#0a4c8b',
              // '&:hover': { backgroundColor: '#083968' },
              borderRadius: 1,
              fontWeight: 'bold',
              fontSize: '1.1rem'
            }}
          >
            SUBMIT
          </Button>
        </Box>
      </Card>
    </Box>
  );
};



export default Register;;