import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { MyContext } from "../../context/Context";
import axios from "axios";
import Swal from "sweetalert2";

const ContactUsDetails = () => {
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  const [formValues, setFormValues] = useState({
    contactType: "",
    description: "",
  });

  const { PORT, getContactUsDetails, contactUsDetails } = useContext(MyContext);

  useEffect(() => {
    getContactUsDetails();
  }, []);

  // Open & Close Modals
  const handleOpen = () => {
    setFormValues({ contactType: "", description: "" });
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleEditOpen = (index) => {
    setEditIndex(index);
    setFormValues({
      contactType: contactUsDetails[index].contactType,
      description: contactUsDetails[index].description,
    });
    setEditOpen(true);
  };
  const handleEditClose = () => setEditOpen(false);

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  // Handle Add New Contact Us Details
  const handleSubmit = async () => {
    try {
      const res = await axios.post(`${PORT}/admin/ContactDetails/addContactUsDetails`, formValues);
      if (res.data.message === "Contact Us Details added successfully") {
        Swal.fire("Success", "Contact Us Details added successfully!", "success");
        getContactUsDetails();
        setOpen(false);
      }
    } catch (error) {
      Swal.fire("Error", "Failed to add Contact Us Details!", "error");
    }
  };

  // Handle Edit Contact Us Details
  const handleEditSubmit = async () => {
    try {
      await axios.post(`${PORT}/admin/ContactDetails/editContactUsDetails/${contactUsDetails[editIndex]._id}`, formValues);
      Swal.fire("Updated!", "Contact Us Details updated successfully!", "success");
      getContactUsDetails();
      setEditOpen(false);
    } catch (error) {
      Swal.fire("Error", "Failed to update Contact Us Details!", "error");
    }
  };

  // Handle Delete Contact Us Details
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.post(`${PORT}/admin/ContactDetails/deleteContactUsDetails/${id}`);
          Swal.fire("Deleted!", "Contact Us Details has been deleted.", "success");
          getContactUsDetails();
        } catch (error) {
          Swal.fire("Error", "Failed to delete Contact Us Details!", "error");
        }
      }
    });
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3 px-4">
        <h3 className="text-muted">Contact Us Details</h3>
        <Button variant="success" onClick={handleOpen}>ADD</Button>
      </div>

      {/* Add Contact Us Modal */}
      <Modal show={open} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Contact Us Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Contact Type</Form.Label>
              <Form.Select name="contactType" value={formValues.contactType} onChange={handleChange}>
                <option value="">Select Type</option>
                <option value="Contact">Contact</option>
                <option value="Address">Address</option>
                <option value="Website">Website</option>
              </Form.Select>
            </Form.Group>

            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={3} name="description" value={formValues.description} onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="success" onClick={handleSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>

      {/* Display Contact Us Details */}
      <div className="row d-flex justify-content-center">
        {contactUsDetails?.length > 0 ? (
          contactUsDetails.map((item, index) => (
            <div key={item._id} className="col-md-5">
              <div className="card p-3 mb-4 shadow-sm">
                <h5 className="text-center mt-2">{item.contactType}</h5>
                <p>{item.description}</p>
                <div className="d-flex justify-content-between">
                  <Button variant="outline-success" onClick={() => handleEditOpen(index)}>Edit</Button>
                  <Button variant="outline-danger" onClick={() => handleDelete(item._id)}>Delete</Button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">No Contact Us Details available</p>
        )}
      </div>

      {/* Edit Contact Us Modal */}
      <Modal show={editOpen} onHide={handleEditClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Contact Us Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Contact Type</Form.Label>
              <Form.Select name="contactType" value={formValues.contactType} onChange={handleChange}>
                <option value="">Select Type</option>
                <option value="Contact">Contact</option>
                <option value="Address">Address</option>
                <option value="Website">Website</option>
              </Form.Select>
            </Form.Group>

            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={3} name="description" value={formValues.description} onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>Close</Button>
          <Button variant="success" onClick={handleEditSubmit}>Update</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ContactUsDetails;
