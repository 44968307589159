import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { MyContext } from "../../context/Context";
import axios from "axios";
import Swal from "sweetalert2";

const HomeAbout = () => {
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  
  const [formValues, setFormValues] = useState({
    image: null,
    title: "",
    link: "",
    description: "",
    heading:""
  });

  const { PORT, getHomeAbout , homeAbout } = useContext(MyContext);

  useEffect(() => {
    getHomeAbout();
  }, []);

  // Open & Close Modals
  const handleOpen = () => {
    setFormValues({ image: null, title: "", link: "", description: "",heading:"" });
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleEditOpen = (index) => {
    setEditIndex(index);
    setFormValues({
      title: homeAbout[index].title,
      description: homeAbout[index].description,
      image: homeAbout[index].image,
      link: homeAbout[index].link,
      heading: homeAbout[index].heading,

    });
    setEditOpen(true);
  };
  const handleEditClose = () => setEditOpen(false);

  const handleChange = (e) => {
    const { name, type } = e.target;
    if (type === "file") {
      setFormValues({ ...formValues, [name]: e.target.files[0] });
    } else {
      setFormValues({ ...formValues, [name]: e.target.value });
    }
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("title", formValues.title);
      formData.append("description", formValues.description);
      formData.append("link", formValues.link);
      formData.append("heading", formValues.heading);

      if (formValues.image) formData.append("image", formValues.image);

      const res = await axios.post(`${PORT}/admin/HomeAbout/addHomeAbout`, formData);
      
      if (res.data.message === "Home About added successfully") {
        Swal.fire("Success", "Home About added successfully!", "success");
        getHomeAbout();
        setOpen(false);
      }
    } catch (error) {
      Swal.fire("Error", "Failed to add home About!", "error");
    }
  };

  // Handle Edit Home About
  const handleEditSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("title", formValues.title);
      formData.append("description", formValues.description);
      formData.append("link", formValues.link);
      formData.append("heading", formValues.heading);

      if (formValues.image && formValues.image instanceof File) {
        formData.append("image", formValues.image);
      }
  
      await axios.post(`${PORT}/admin/HomeAbout/editHomeAbout/${homeAbout[editIndex]._id}`, formData);
      
      Swal.fire("Updated!", "Home About updated successfully!", "success");
      getHomeAbout();
      setEditOpen(false);
    } catch (error) {
      Swal.fire("Error", "Failed to update home About!", "error");
    }
  };
  

  // Handle Delete Home About
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.post(`${PORT}/admin/HomeAbout/deleteHomeAbout/${id}`);
          Swal.fire("Deleted!", "Home About has been deleted.", "success");
          getHomeAbout();
        } catch (error) {
          Swal.fire("Error", "Failed to delete home About!", "error");
        }
      }
    });
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3 px-4">
        <h3 className="text-muted">Home About</h3>
        <Button variant="success" onClick={handleOpen}>ADD</Button>
      </div>

      {/* Add About Modal */}
      <Modal show={open} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New About Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Upload About image</Form.Label>
              <Form.Control type="file" name="image" onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Heading</Form.Label>
              <Form.Control type="text" name="heading" value={formValues.heading} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" name="title" value={formValues.title} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Upload link</Form.Label>
              <Form.Control type="text" name="link" onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={3} name="description" value={formValues.description} onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="success" onClick={handleSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>

      {/* Display Abouts */}
      <div className="row d-flex justify-content-center">
        {homeAbout?.length > 0 ? (
          homeAbout.map((item, index) => (
            <div key={index} className="col-md-5">
              <div className="card p-3 mb-4 shadow-sm">
                <h4 className="text-center mt-2">{item.heading}</h4>
                <img src={`${PORT}/${item.image}`} className="img-fluid rounded" alt="Slide" />
                <h5 className="text-center mt-2">{item.title}</h5>
                <p>{item.description}</p>
                <h5 className="text-center mt-2">{item.link}</h5>
                <div className="d-flex justify-content-between">
                  <Button variant="outline-success" onClick={() => handleEditOpen(index)}>Edit</Button>
                  <Button variant="outline-danger" onClick={() => handleDelete(item._id)}>Delete</Button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">No Abouts available</p>
        )}
      </div>

      {/* Edit About Modal */}
      <Modal show={editOpen} onHide={handleEditClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit About Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Upload About image</Form.Label>
              <Form.Control type="file" name="image" onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" name="title" value={formValues.title} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Heading</Form.Label>
              <Form.Control type="text" name="heading" value={formValues.heading} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Upload link</Form.Label>
              <Form.Control type="text" name="link" value={formValues.link} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={3} name="description" value={formValues.description} onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>Close</Button>
          <Button variant="success" onClick={handleEditSubmit}>Update</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HomeAbout;
