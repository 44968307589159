import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../Components/Assets/Logo.png";
import Swal from "sweetalert2";
import { FaHeart, FaLock, FaSignOutAlt } from "react-icons/fa";
import { 
  IconButton, 
  Badge,
  Tooltip,
  Drawer,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  Button,
  TextField
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { ShoppingCart } from 'lucide-react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import { useShopping } from "./ProductContext";
import { MyContext } from "../context/Context";

const Navbar = () => {
  const [hasShadow, setHasShadow] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      const user = JSON.parse(userData);
      setUserName(user.name);
    }
  }, []);

  const { 
    wishlistCount, 
    cartCount, 
    showCart, 
    setShowCart, 
    checkoutStep, 
    setCheckoutStep,
    cart,
    updateQuantity,
    removeFromCart,
    totalPrice
  } = useShopping();

  console.log('wishlistCount',wishlistCount);
  
  const {
    PORT,
    getProducts,
    products,
    getDivision, division
  } = useContext(MyContext);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setHasShadow(true);
      } else {
        setHasShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  
  // Handle logout
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, Logout",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        setUserName(""); 
        setIsLoggedIn(false);
        navigate("/login");
  
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Logout Successful",
          showConfirmButton: false,
          timer: 2000,
        });
  
        window.location.reload(); // Reload after logout
      }
    });
  };
  

  // Scroll to top on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
    getProducts();
    getDivision();
  }, []);

  // Function to handle proceeding to checkout
  const handleProceedToCheckout = () => {
    setCheckoutStep(1);
  };

  // Function to place order
  const handlePlaceOrder = () => {
    Swal.fire({
      icon: 'success',
      title: 'Order Placed Successfully!',
      text: 'Thank you for your purchase.',
      confirmButtonColor: 'rgb(150,234,212)',
    }).then(() => {
      // Clear cart and close drawer
      localStorage.removeItem('cart');
      setShowCart(false);
      setCheckoutStep(0);
      window.location.reload();
    });
  };


  const handleNavigate = () => {
    navigate("/about-us#divisions");
  };

  // Function to render cart/checkout content
  const renderCheckoutContent = () => {
    switch(checkoutStep) {
      case 0:
        return (
          <>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
              Shopping Cart ({cartCount} items)
            </Typography>
            
            {cart.length === 0 ? (
              <Typography>Your cart is empty</Typography>
            ) : (
              <>
                <List sx={{ width: '100%' }}>
                {cart.map((item) => (
  <React.Fragment key={item._id}>
    <ListItem
      secondaryAction={
        <IconButton edge="end" onClick={() => removeFromCart(item._id)}>
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar src={`${PORT}/${item.image}`} variant="square" sx={{ width: 60, height: 60 }} />
      </ListItemAvatar>
      <ListItemText
        primary={item.title}
        secondary={`$${item.price}`}
        sx={{ ml: 2 }}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
        <IconButton size="small" onClick={() => updateQuantity(item._id, item.quantity - 1)}>
          <RemoveIcon fontSize="small" />
        </IconButton>
        <Typography sx={{ mx: 1 }}>{item.quantity}</Typography>
        <IconButton size="small" onClick={() => updateQuantity(item._id, item.quantity + 1)}>
          <AddIcon fontSize="small" />
        </IconButton>
      </Box>
      <Typography sx={{ ml: 2, fontWeight: 'bold' }}>
        ${(item.price * item.quantity).toFixed(2)}
      </Typography>
    </ListItem>
    <Divider />
  </React.Fragment>
))}


                </List>
                
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6">
                    Total: ${totalPrice.toFixed(2)}
                  </Typography>
                  <Button 
                    variant="contained"
                    className='text-muted'
                    onClick={handleProceedToCheckout}
                    sx={{ 
                      backgroundColor: 'rgb(150,234,212)', 
                      color: 'black',
                      '&:hover': {
                        backgroundColor: 'rgb(130,214,192)',
                      }
                    }}
                  >
                    Proceed to Checkout
                  </Button>
                </Box>
              </>
            )}
          </>
        );
      case 1:
        return (
          <>
            <Typography variant="h6" sx={{ mb: 3 }}>Checkout</Typography>
            
            <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>Shipping Information</Typography>
            <Box sx={{ mb: 3 }}>
              <TextField label="Full Name" fullWidth sx={{ mb: 2 }} />
              <TextField label="Address Line 1" fullWidth sx={{ mb: 2 }} />
              <TextField label="Address Line 2" fullWidth sx={{ mb: 2 }} />
              <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField label="City" fullWidth />
                <TextField label="State" fullWidth />
                <TextField label="Zip Code" fullWidth />
              </Box>
            </Box>
            
            <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>Payment Information</Typography>
            <Box sx={{ mb: 3 }}>
              <TextField label="Card Number" fullWidth sx={{ mb: 2 }} />
              <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField label="Expiry Date" fullWidth />
                <TextField label="CVV" fullWidth />
              </Box>
            </Box>
            
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
              <a href="" className='text-decoration-none text-black' onClick={(e) => {
                e.preventDefault();
                setCheckoutStep(0);
              }}> Back to Cart</a>
              <Box>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Total: ${totalPrice.toFixed(2)}
                </Typography>
                <Button 
                  variant="contained"
                  onClick={handlePlaceOrder}
                  fullWidth
                  sx={{ 
                    backgroundColor: 'rgb(150,234,212)', 
                    color: 'black',
                    '&:hover': {
                      backgroundColor: 'rgb(130,214,192)',
                    }
                  }}
                >
                  Place Order
                </Button>
              </Box>
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  // Regular menu items without login/logout
  const menuItems = [
    { name: "HOME", path: "/" },
    { name: "ABOUT US", path: "/about-us" },
    { name: "PRODUCTS", path: "/products" },  
    { name: "BRANDS", path: "/Brands" },
    { name: "DIVISIONS", isDivision: true },
    { name: "CAREER", path: "/career" },
    { name: "CONTACT US", path: "/contact-us" }
  ];
  
  return (
    <>
      {/* Navbar */}
      <nav
  className="navbar navbar-expand-lg navbar-light py-3 fixed-top"
  style={{
    backgroundColor: "#ffffff",
    transition: "box-shadow 0.3s ease-in-out",
    boxShadow: hasShadow ? "0px 4px 10px rgba(0,0,0,0.1)" : "none",
  }}
>
  <div className="container">
    {/* Left Side - Logo */}
    <a className="navbar-brand me-auto" href="/">
      <img src={Logo} alt="Logo" style={{ width: "300px" }} />
    </a>

    {/* Mobile Toggle Button */}
    <button
      className="navbar-toggler mb-3"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
    >
      <span className="navbar-toggler-icon"></span>
    </button>

    {/* Center - Menu Items */}
    <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
      <ul className="navbar-nav"> {/* Spacing between items */}
        {menuItems.map((item) =>
          item.isDivision ? (
            <li
              className="nav-item position-relative"
              key={item.name}
              onMouseEnter={() => {
                const dropdown = document.getElementById("divisionsMenu");
                if (dropdown) dropdown.classList.add("show");
              }}
              onMouseLeave={() => {
                const dropdown = document.getElementById("divisionsMenu");
                if (dropdown) dropdown.classList.remove("show");
              }}
            >
              <a className="nav-link fw-medium px-3 text-secondary" href="#" role="button">
                {item.name}
              </a>

              {/* Stylish Dropdown */}
              <div
                className="dropdown-menu position-absolute start-50 translate-middle-x border-0 shadow p-3"
                id="divisionsMenu"
                style={{
                  top: "100%",
                  minWidth: "600px",
                  height: "auto",
                  textAlign: "center",
                  backgroundColor: "#fff",
                }}
                aria-labelledby="divisionsMenu"
              >
                <div className="container ">
                  <div className="row g-3">
                    {division &&
                      division.map((div, index) => (
                        <div className="col-6" key={index}>
                        <a
                          onClick={(e) => {
                            e.preventDefault(); 
                            handleNavigate();
                          }}
                          className="dropdown-item text-center fw-medium border rounded shadow-sm"
                          href="#"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        
                            color: "#333",
                            backgroundColor: "#f8f9fa",
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            padding: "10px",
                            textAlign: "center",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "normal",
                            transition: "0.3s ease-in-out",
                          }}
                        >
                          {div.description}
                        </a>
                      </div>
                      
                      ))}
                  </div>
                </div>
              </div>
            </li>
          ) : (
            <li className="nav-item" key={item.name}>
              <NavLink
                className={({ isActive }) =>
                  `nav-link fw-medium px-3 ${isActive ? "text-dark fw-bold" : "text-secondary"}`
                }
                to={item.path}
              >
                {item.name}
              </NavLink>
            </li>
          )
        )}
      </ul>
    </div>

    {/* Right Side - Wishlist, Cart, Login */}
    <div className="d-flex align-items-center ms-auto gap-3"> {/* Space between icons */}
      {/* Wishlist */}
      <NavLink className="nav-link" to="/wishlist">
        <Tooltip title="View Wishlist">
          <Badge badgeContent={wishlistCount} color="error">
            <IconButton style={{ backgroundColor: "#E0F7FA", borderRadius: "50%" }}>
              <FavoriteIcon sx={{ color: "red" }} />
            </IconButton>
          </Badge>
        </Tooltip>
      </NavLink>

      {/* Cart */}
      <NavLink className="nav-link me-2">
        <Tooltip title="View Cart">
          <Badge badgeContent={cartCount} color="error">
            <IconButton
              onClick={() => setShowCart(true)}
              style={{ backgroundColor: "#E0F7FA", borderRadius: "50%" }}
            >
              <ShoppingCart size={20} color="black" />
            </IconButton>
          </Badge>
        </Tooltip>
      </NavLink>

      {/* Login / User Section */}
      <NavLink className="nav-link me-4" to="/login">
        {/* <div className="text-nowrap"> */}
       <div className="fw-bold text-muted">
            Profile 
          </div>
          <div className="fw-bold text-muted"> 
          {userName ? `Hi, ${userName}` : "Login / Signup"}
        {/* </div> */}
        </div>
      </NavLink>

      {/* Logout Button */}
      {userName && (
        <button className="btn btn-muted btn-md d-flex align-items-center" onClick={handleLogout}>
          <FaSignOutAlt className="" />
        </button>
      )}
    </div>
  </div>
</nav>



      {/* Cart drawer */}
      <Drawer
        anchor="right"
        open={showCart}
        onClose={() => {
          setShowCart(false);
          setCheckoutStep(0);
        }}
        PaperProps={{
          sx: { width: { xs: '100%', sm: '450px' }, padding: 3 }
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h5" sx={{fontWeight:'bold'}}>
            {checkoutStep === 0 ? 'Your Cart' : 'Checkout'}
          </Typography>
          <IconButton onClick={() => {
            setShowCart(false);
            setCheckoutStep(0);
          }}>
            <CloseIcon />
          </IconButton>
        </Box>
        
        {renderCheckoutContent()}
      </Drawer>

      {/* Add margin-top to prevent content being hidden behind navbar */}
      <div style={{ marginTop: "80px" }}></div>
    </>
  );
};

export default Navbar;