import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { TextField, Button, Typography, useMediaQuery } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import InquiryImg4 from "../Components/Assets/InquiryImg4.jpg";
import Swal from "sweetalert2";
import axios from "axios";
import { MyContext } from "../context/Context";


const ContactUs = () => {

  const { PORT, getContactUsDetails, contactUsDetails } = useContext(MyContext);


  useEffect(() => {
    window.scrollTo(0, 0);
    getContactUsDetails();
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const isMobile = useMediaQuery("(max-width: 768px)");

    const [formData, setFormData] = useState({
      name: "",
      contact: "",
      email: "",
      message: "", 
      createdAt: new Date(),
    });

      const [errors, setErrors] = useState({
        email: "",
        contact: "",
      });

      const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
    
      const validateContact = (contact) => {
        const contactRegex = /^[0-9]{10,}$/;
        return contactRegex.test(contact);
      };

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    
        if (name === "email") {
          setErrors({ ...errors, email: validateEmail(value) ? "" : "Invalid email format" });
        }
    
        if (name === "contact") {
          setErrors({ ...errors, contact: validateContact(value) ? "" : "Invalid contact number (must be at least 10 digits)" });
        }
      };

      const  handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validateEmail(formData.email)) {
          setErrors({ ...errors, email: "Invalid email format" });
          return;
        }
    
        if (!validateContact(formData.contact)) {
          setErrors({ ...errors, contact: "Invalid contact number (must be at least 10 digits)" });
          return;
        }
    
     
        try {
          const response = await axios.post("https://kushalmedicals.com/admin/ContactUs/addContactUs", formData, {
          });
    
          if (response.data.message === "Contact Us added successfully") {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Application submitted successfully!",
              confirmButtonColor: "#28a745",
            });
    
            setFormData({ name: "", contact: "", email: "", position: "", experience: "", message: "", resume: null });
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Failed to submit application. Please try again!",
            confirmButtonColor: "#d33",
          });
        }
      };
    
  return (
    <div
      style={{
        backgroundImage: `url(${InquiryImg4})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        minHeight: "100vh",
        position: "relative",
        overflow: "hidden", // Prevent horizontal scrolling
      }}
    >
      {/* Overlay */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backdropFilter: "blur(1px)",
        }}
      ></div>

      {/* Main Content - Added top padding to account for navbar */}
      <div 
        className="container position-relative py-4 py-md-5" 
        style={{ 
          marginTop: isMobile ? "100px" : "" // Adjust these values based on your navbar height
        }}
      >
        <div className="row justify-content-center mx-0">
          {/* Inquiry Form Section */}
          <div className="col-12 col-md-10 col-lg-8 px-2 px-sm-3" data-aos="zoom-in">
            <div
              className="p-3 p-md-4 shadow-lg rounded text-center"
              style={{
                backdropFilter: "blur(5px)",
                boxShadow: "0px 4px 20px rgba(16, 4, 4, 0.5)",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                margin: "0 auto", // Center the form
                maxWidth: isMobile ? "100%" : "90%", // Control width on mobile
              }}
            >
              {/* Form Heading */}
              <Typography
                className="py-2 rounded shadow text-muted mb-3"
                sx={{
                  fontWeight: "bold",
                  fontSize: isMobile ? "20px" : "22px",
                  padding: "10px",
                  borderRadius: "8px",
                  display: "inline-block",
                }}
              >
                CONTACT US
              </Typography>
              <form onSubmit={handleSubmit}>

            <TextField fullWidth label="Name" name="name" value={formData.name} onChange={handleChange} variant="standard" sx={{ mb: 2 }} required />
            <TextField fullWidth label="Contact No" name="contact" value={formData.contact} onChange={handleChange} variant="standard" sx={{ mb: 2 }} error={!!errors.contact} helperText={errors.contact} required />
            <TextField fullWidth label="Email" name="email" value={formData.email} onChange={handleChange} variant="standard" sx={{ mb: 2 }} error={!!errors.email} helperText={errors.email} required />
            <TextField fullWidth label="Message" name="message" value={formData.message} onChange={handleChange} variant="standard" multiline rows={3} sx={{ mb: 3 }} />
            <Button
                onClick={handleSubmit}
                variant="outlined"
                size="large"
                fullWidth
                sx={{
                  borderRadius: "8px",
                  textTransform: "none",
                  fontSize: isMobile ? "14px" : "16px",
                  fontWeight: "bold",
                  color: "#000",
                  borderColor: "#000",
                  "&:hover": {
                    backgroundColor: "#000",
                    color: "#fff",
                  },
                }}
              >
                Submit
              </Button>
</form>
              {/* Submit Button */}
             
            </div>
          </div>
        </div>

        {/* Address, Contact, Website Section */}
 <div  className="row g-3 g-md-4 justify-content-center mt-4 mt-md-5 mx-0">
 {/* Address Card */}
 {contactUsDetails.map((item,index)=>(

 <div key={index} className="col-12 col-sm-6 col-md-4 px-2 px-sm-3" data-aos="fade-right" data-aos-delay="100">
   <div 
     className="card shadow border-0 h-100" 
     style={{ 
       backgroundColor: "rgba(255, 255, 255, 0.85)",
       margin: isMobile ? "0 auto" : "0",
       maxWidth: isMobile ? "95%" : "100%"
     }}
   >
     <div className="card-body text-black rounded p-3">
       <h4 className="card-title text-uppercase fw-bold fs-5">{item.contactType}</h4>
       <p className="card-text text-justify" style={{ fontSize: isMobile ? "16px" : "18px",display: "flex", alignItems: "center", marginBottom: "8px" }}>
{item.description}
       </p>
     </div>
   </div>
 </div>
))} 
</div>
      

        {/* Map Section */}
        <div className="mt-4 mt-md-5 px-2 px-sm-3" data-aos="zoom-in">
          <iframe
            loading="lazy"
            src="https://maps.google.com/maps?q=SHOPPING%20CENTRE%2CKARAMPURA%2C%20MOTI%20NAGAR%2C%20NEW%20DELHI-110015&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near"
            title="SHOPPING CENTRE,KARAMPURA, MOTI NAGAR, NEW DELHI-110015"
            aria-label="SHOPPING CENTRE,KARAMPURA, MOTI NAGAR, NEW DELHI-110015"
            className="w-100"
            style={{
              height: isMobile ? "300px" : "400px",
              border: "0",
              borderRadius: "12px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",
              maxWidth: isMobile ? "95%" : "100%",
              margin: "0 auto",
            }}
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;