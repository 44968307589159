import React, { useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import AOS from "aos";
import "aos/dist/aos.css";

import BrandsImg1 from "../Components/Assets/BrandsImg1.png";
import BrandsImg2 from "../Components/Assets/BrandsImg2.png";
import BrandsImg3 from "../Components/Assets/BrandsImg3.png";
import BrandsImg4 from "../Components/Assets/BrandsImg4.png";
import BrandsImg5 from "../Components/Assets/BrandsImg5.png";
import BrandsImg6 from "../Components/Assets/BrandsImg6.png";
import BrandsImg7 from "../Components/Assets/BrandsImg7.png";
import BrandsImg8 from "../Components/Assets/BrandsImg8.png";
import BrandsImg9 from "../Components/Assets/BrandsImg9.png";
import BrandsImg10 from "../Components/Assets/BrandsImg10.png";
import BrandsImg11 from "../Components/Assets/BrandsImg11.png";
import BrandsImg12 from "../Components/Assets/BrandsImg12.png";
import { MyContext } from "../context/Context";
import { NavItem } from "react-bootstrap";

const Brands = () => {

  const { PORT, getOurClients , ourClients } = useContext(MyContext);


  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({ duration: 1000 });
    getOurClients();
  }, []);

  return (
    <div className="container" style={{ marginTop: "90px" }}>
      <h3 className="py-2 rounded shadow-lg text-center fw-bold text-muted">
   OUR BRANDS
      </h3>
      <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-1 mt-3"> {/* Reduced gap */}
        {ourClients.map((item, index) => (
          <div key={index} className="col">
            <div className="card border-0 shadow-sm p-3 rounded text-center" data-aos="fade-up">
              <img
                src={`${PORT}/${item.image}`}
                alt={`Brand ${index + 1}`}
                className="img-fluid rounded"
                style={{
                  width: "100%",
                  height: "120px",
                  objectFit: "contain",
                  transition: "transform 0.3s ease-in-out",
                }}
                onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Brands;
