import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules"; // Removed Navigation
import "swiper/css";
import "swiper/css/pagination";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useShopping } from "./ProductContext";
import CloseIcon from '@mui/icons-material/Close';
import "../Components/Styles/ProductSlider.css"
import { ShoppingCart } from "lucide-react";
import { 
  TextField, 
  InputAdornment, 
  IconButton, 
  Rating,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  Box,
  Typography,
  Button
} from '@mui/material';
import { MyContext } from "../context/Context";
import axios from "axios";
import Swal from "sweetalert2";


const ProductsSlider = () => {

  const [selectedProduct, setSelectedProduct] = useState(null);

  const { PORT, getProducts , products } = useContext(MyContext);


  useEffect(()=>{
    window.scrollTo(0,0)
    getProducts();
  },[])
  


      const { favorites, toggleFavorite, addToCart,
      } = useShopping();
    
const navigate = useNavigate();

const handleAddToCart = (product) => {
  // Use the addToCart function from context
  addToCart(product);
};


const [formData, setFormData] = useState({
  name: "",
  contact: "",
  email: "",
  message: "",
  createdAt: new Date(),
});

const [errors, setErrors] = useState({
  email: "",
  contact: "",
});

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const validateContact = (contact) => {
  const contactRegex = /^[0-9]{10,}$/;
  return contactRegex.test(contact);
};

const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });

  if (name === "email") {
    setErrors({
      ...errors,
      email: validateEmail(value) ? "" : "Invalid email format",
    });
  }

  if (name === "contact") {
    setErrors({
      ...errors,
      contact: validateContact(value)
        ? ""
        : "Invalid contact number (must be at least 10 digits)",
    });
  }
};

const handleProductsSubmit = async (e) => {
  e.preventDefault();

  if (!validateEmail(formData.email)) {
    setErrors({ ...errors, email: "Invalid email format" });
    return;
  }

  if (!validateContact(formData.contact)) {
    setErrors({
      ...errors,
      contact: "Invalid contact number (must be at least 10 digits)",
    });
    return;
  }

  try {
    const response = await axios.post(
      "https://kushalmedicals.com/admin/HomeProductEnquiry/addHomeProductEnquiry",
      formData
    );

    console.log("API Response:", response);

    if (
      response.data.message === "Home Product Enquiry added successfully"
    ) {
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Home Product Enquiry added successfully!",
        confirmButtonColor: "#28a745",
      });
      setSelectedProduct(false);
      setFormData({ name: "", contact: "", email: "", message: "" });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Something went wrong!",
        text: "Server did not return expected response.",
        confirmButtonColor: "#ffc107",
      });
    }
  } catch (error) {
    console.error("API Error:", error); // Debugging

    Swal.fire({
      icon: "error",
      title: "Error!",
      text: "Failed to submit Wishlist Enquiry Enquiry. Please try again!",
      confirmButtonColor: "#d33",
    });
  }
};


  return (
    <div className="container">
    <div className="row justify-content-center align-items-center my-5">
      <div className="col-12 text-center">
        <h4 className="fw-bold text-muted position-relative py-2 rounded shadow-sm">
          PRODUCTS
        </h4>
        <div className="position-relative overflow-hidden rounded shadow-lg">
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={15}
            slidesPerView={1}
            pagination={{ clickable: true }}
            autoplay={{ delay: 3000 }}
            loop={true}
            breakpoints={{
              320: { slidesPerView: 1 },
              576: { slidesPerView: 2 },
              992: { slidesPerView: 3 },
              1200: { slidesPerView: 4 }
            }}
            className="py-3"
          >
            {products.map((product, index) => (
              <SwiperSlide key={index} className="d-flex justify-content-center mb-3">
                <div 
                  className="card product-card shadow-lg rounded position-relative mx-auto" 
                  style={{
                    width: "100%",
                    maxWidth: "280px",
                    cursor: "pointer",
                    transition: "transform 0.3s ease",
                  }}
                  onClick={() => navigate(`/product/${product._id}`)}
                  onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.03)"}
                  onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"}
                >
                  <div className="text-center p-3">
                    <img
                      src={`${PORT}/${product.image}`}
                      alt={product.name}
                      className="img-fluid mx-auto d-block"
                      style={{
                        height: "150px",
                        objectFit: "contain"
                      }}
                    />
                    
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "8px",
                        right: "8px",
                        backgroundColor: "rgb(150,234,212)",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleFavorite(product);
                      }}
                    >
                      <FavoriteIcon
                        sx={{
                          color: favorites.has(product._id) ? "red" : "gray",
                          fontSize: "24px",
                        }}
                      />
                    </IconButton>
                    
                    <div className="card-body px-2 py-3">
                      <h5 className="card-title">{product.title}</h5>
                      <div className="text-center mb-2">
                        <Rating value={4} readOnly size="small" />
                        <h4 className="mb-3 text-muted">Price: ${product.price}</h4>
                      </div>
                      
                      <div className="d-flex justify-content-between">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedProduct(product);
                          }}
                          className="btn btn-outline-success"
                        >
                          VIEW
                        </button>
                         <button 
                                                  onClick={() => addToCart(product)} 
                                                  className="btn btn-outline-success"
                                                >
                                                  <ShoppingCart size={16} />
                                                </button>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
    <Dialog
          open={!!selectedProduct}
          onClose={() => setSelectedProduct(null)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            style: {
              borderRadius: '12px',
              overflow: 'hidden'
            }
          }}
        >
          {selectedProduct && (
            <>
              <DialogTitle
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  color: 'black',
                  backgroundColor:"rgb(150,234,212)"
                }}
              >
                <Typography variant="h6" component="div" style={{ fontWeight: 600 }}>
                  {selectedProduct.name}
                </Typography>
                <IconButton
                  onClick={() => setSelectedProduct(null)}
                  style={{ color: 'white' }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Box sx={{ p: 2 }}>
                  <img
                    src={`${PORT}/${selectedProduct.image}`}
                    alt={selectedProduct.name}
                    style={{
                      width: '100%',
                      height: '400px',
                      objectFit: 'contain',
                      borderRadius: '8px',
                      marginBottom: '20px'
                    }}
                  />
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {selectedProduct.description}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Rating value={4} readOnly size="large" />
                    <Typography variant="body2" color="text.secondary">
                      4.0 out of 5
                    </Typography>
                    <button 
                      className="btn btn-outline-success" 
                      onClick={() => {
                        handleAddToCart(selectedProduct);
                        setSelectedProduct(null);
                      }}
                    >
                      Add to Cart
                    </button>
                  </Box>
                </Box>
                  <Box
                                      >
                                        <Typography
                                          id="enquiry-modal"
                                          variant="h6"
                                          component="h2"
                                          sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}
                                          className='text-muted'
                                        >
                                          Enquiry for : {selectedProduct.title}
                
                                        </Typography>
                              
                                        {/* Name Input */}
                                         <form onSubmit={handleProductsSubmit}>
                                                     {/* Name Input */}
                                                     <TextField
                                                       fullWidth
                                                       name="name"
                                                       value={formData.name}
                                                       onChange={handleChange}
                                                       label="Name"
                                                       variant="outlined"
                                                       sx={{ mb: 2 }}
                                                     />
                                       
                                                     {/* Email Input */}
                                                     <TextField
                                                       fullWidth
                                                       label="Email"
                                                       name="email"
                                                       value={formData.email}
                                                       onChange={handleChange}
                                                       error={!!errors.email}
                                                       helperText={errors.email}
                                                       variant="outlined"
                                                       sx={{ mb: 2 }}
                                                     />
                                       
                                                     {/* Mobile Input */}
                                                     <TextField
                                                       fullWidth
                                                       label="Mobile"
                                                       name="contact"
                                                       value={formData.contact}
                                                       onChange={handleChange}
                                                       error={!!errors.contact}
                                                       helperText={errors.contact}
                                                       variant="outlined"
                                                       sx={{ mb: 2 }}
                                                     />
                                       
                                                     {/* Message Input */}
                                                     <TextField
                                                       fullWidth
                                                       label="Message"
                                                       variant="outlined"
                                                       multiline
                                                       name="message"
                                                       value={formData.message}
                                                       onChange={handleChange}
                                                       rows={3}
                                                       sx={{ mb: 3 }}
                                                     />
                                       
                                                     <Button
                                                       type="submit"
                                                       variant="contained"
                                                       fullWidth
                                                       sx={{
                                                         backgroundColor: "rgb(150,234,212)",
                                                         fontWeight: "bold",
                                                         "&:hover": { backgroundColor: "rgb(120,204,182)" },
                                                       }}
                                                     >
                                                       Submit
                                                     </Button>
                                                   </form>
                                      </Box>
              </DialogContent>
            </>
          )}
        </Dialog>
  </div>
  
  );
};

export default ProductsSlider;
